<p-toast key="sims-preferences-message"></p-toast>
<div class="banner">
	<div class="banner-heading">
		<p>{{'wbdo.sims.header' | translate }}</p>
		<p style="text-align: end">{{'wbdo.sims.mostStandardLabel' | translate }}</p>
	</div>
</div>

<sims-preferences [dealerCode]="this.dealerCode"
				  [market]="this.market"
				  [division]="this.division">
</sims-preferences>
<div class="flex flex-row gap-2 acknowledge-and-submit field-checkbox" style="justify-content: space-between">
	<div class="field-checkbox" style="margin-bottom: 0">
		<p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary" id="binary" class="flex"></p-checkbox>
		<label style="max-width: 467px; font-weight: 400" class="flex text-xs" for="binary">{{'wbdo.sims.acknowledgementNotice' | translate }}</label>
	</div>

	<div id="buttonsSIMS" class="flex gap-1">
		<td>
			<button pButton pRipple type="button" class="p-button-outlined" (click)=cancelButton()>{{'wbdo.sims.cancel' | translate }}</button>
		</td>
		<td>
			<button pRipple type="button" style="min-width: 78px" [disabled]="!checked" (click)=submitSimsPreferences() pButton class="justify-content-center">{{'wbdo.sims.ok' | translate }}</button>
		</td>
	</div>
</div>
<div class="last_updated flex justify-content-end align-items-center" style="min-height: 2rem" [hidden]="!this.updatedUser">
	{{'wbdo.sims.lastUpdate' | translate }}
	{{this.updatedUser}}, {{this.updatedTimestamp}}
</div>

