<p-table id="wholesale-control-table" styleClass="p-datatable-striped p-datatable-gridlines"
		 [value]="wholesaleControl"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="text-center" scope="col">{{ 'wbdo.wholesale.control' | translate }}</th>
			<th class="text-center" scope="col">{{ 'wbdo.wholesale.limit' | translate }} </th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-control>
		<tr>
			<td class="text-center">{{control.description || control.feature}}</td>
			<td class="text-center">{{control.target}}</td>
		</tr>
	</ng-template>
</p-table>
