import {Component, Input} from '@angular/core';
import {SimsPreferencesService, VehicleLine} from '../../service/sims-preferences-service';
import {UserInfoService} from '../../service/user-info-service';

@Component({
  	selector: 'toggle-all',
  	templateUrl: './toggle-all.component.html',
  	styleUrls: ['./toggle-all.component.scss'],
	providers: [UserInfoService]
})
export class ToggleAllComponent  {
	@Input() disabled: boolean;
	@Input() vehicleList!: VehicleLine[];

	toggleAll(): void {
		this.vehicleList.forEach(vehicle => {
			vehicle.standardConfig = true;
		});
		this.simsPreferencesService.toggleAllConfig(true);
	}

  	constructor(private simsPreferencesService: SimsPreferencesService) { }
}
