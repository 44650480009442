import {Injectable} from '@angular/core';
import {InventoryMetrics} from '../model/inventory-metrics';
import {DateTime} from 'luxon';
import * as jsPDF from 'jspdf';
import {Workbook} from 'exceljs';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DownloadService {
	blob = new Blob();
	fileName = '';
	constructor(private translate: TranslateService) {
	}
	downloadPdf(rows, cols, fileName, vl, commitment, status): void {
		import('jspdf-autotable').then((x) => {
			let doc = new jsPDF.default('l', 'px', 'a4', true);
			doc.setFontSize(10);
			let labels: string[];
			forkJoin([this.translate.get('wbdo.wholesale.vehicleLine'),
				this.translate.get('wbdo.wholesale.totalVehicleCommitmentVolume'),
				this.translate.get('wbdo.wholesale.commitment')]).subscribe({
				next: (results) => {
					labels = results;
				}
			});
			doc.text([labels[0]+': ' + vl, labels[1]+': ' + commitment, labels[2]+' ' + status],
				30, 30, {align: 'left'});
			(doc as any).autoTable(cols, rows, {
				tableWidth: 'auto',
				startY: 60,
				columnStyles: { text: { cellWidth: 'auto', fontSize: 8}},
				styles: {
					overflow: 'linebreak',
					fontSize: 8
				},
				headStyles :{fillColor : [0, 9, 91]}
			});
			doc = this.createPageNumbers(doc);
			doc.save(fileName);
		});
	}
	downloadExcel(rows, cols, fileName, sheetName, vl, commitment, status, ext): void {
		const workbook = this.createWorkbook();
		const worksheet = workbook.addWorksheet(sheetName);
		let labels: string[];
		forkJoin([this.translate.get('wbdo.wholesale.vehicleLine'),
			this.translate.get('wbdo.wholesale.totalVehicleCommitmentVolume'),
			this.translate.get('wbdo.wholesale.commitment'),
			this.translate.get('wbdo.wholesale.offering'),
			this.translate.get('wbdo.wholesale.availablePepCodes'),
			this.translate.get('wbdo.wholesale.orderRecommendation'),
			this.translate.get('wbdo.wholesale.dealerOrder'),
			this.translate.get('wbdo.wholesale.retailOrderSummary')]).subscribe({
			next: (results) => {
				labels = results;
			}
		});
		worksheet.addRow([labels[0], vl]);
		worksheet.addRow([labels[1], commitment]);
		worksheet.addRow([labels[2], status]);
		worksheet.addRow([]);
		worksheet.addRow([labels[3], labels[4], labels[5], labels[6], labels[7]]);

		worksheet.columns = cols;
		rows.forEach(row => {
			worksheet.addRow(row);
		});
		workbook.xlsx.writeBuffer().then((buffer: any) => {
			this.blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			const fileSaver = require('file-saver');
			fileSaver.saveAs(this.blob, fileName + ext);
		});
	}

	downloadExcelInventoryMetrics(inventoryMetricsData: Map<string, InventoryMetrics[]>, extension: string): void {
		let vehicleLine = '';
		const workbook = this.createWorkbook();
		let labels: string[];
		forkJoin([this.translate.get('wbdo.wholesale.vehicleLine'),
			this.translate.get('wbdo.wholesale.inventoryMetricsDialog.modelYear'),
			this.translate.get('wbdo.wholesale.offering'),
			this.translate.get('wbdo.wholesale.inventoryMetricsDialog.inStock'),
			this.translate.get('wbdo.wholesale.inventoryMetricsDialog.inTransit'),
			this.translate.get('wbdo.wholesale.inventoryMetricsDialog.inSystem')]).subscribe({
			next: (results) => {
				labels = results;
			}
		});
		inventoryMetricsData.forEach(function(value: InventoryMetrics[], key: string) {
			const worksheet = workbook.addWorksheet(key);
			vehicleLine = value[0].vehicleName;
			worksheet.addRow([labels[0], vehicleLine]);
			worksheet.addRow([labels[1], value[0].modelYear]);
			worksheet.addRow([labels[2], labels[3], labels[4], labels[5]]);
			worksheet.addRow([]);
			worksheet.columns = [
				{key: 'offering'},
				{key: 'inStock'},
				{key: 'inTransit'},
				{key: 'inSystem'}
			];
			value.forEach(metric => {
				worksheet.addRow([metric.description, metric.dealerStock, metric.inTransit, metric.inSystem]);
			});
		});

		const fileName: string = vehicleLine + '_' + 'Inventory_Metrics_' + DateTime.now().toFormat('MMM-dd-yyyy');
		workbook.xlsx.writeBuffer().then((buffer: any) => {
			this.blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
			const fileSaver = require('file-saver');
			fileSaver.saveAs(this.blob, fileName + extension);
		});
	}

	downloadPdfInventoryMetrics(inventoryMetricsData: Map<string, InventoryMetrics[]>): void {
		this.buildFileName(inventoryMetricsData.values().next().value[0]['vehicleName']);
		import('jspdf-autotable').then((x) => {
			const doc = new jsPDF.default('l', 'px', 'a4', true);
			doc.setFontSize(10);
			let labels: string[];
			forkJoin([this.translate.get('wbdo.wholesale.vehicleLine'),
				this.translate.get('wbdo.wholesale.inventoryMetricsDialog.modelYear'),
				this.translate.get('wbdo.wholesale.offering'),
				this.translate.get('wbdo.wholesale.inventoryMetricsDialog.inStock'),
				this.translate.get('wbdo.wholesale.inventoryMetricsDialog.inTransit'),
				this.translate.get('wbdo.wholesale.inventoryMetricsDialog.inSystem')]).subscribe({
				next: (results) => {
					labels = results;
				}
			});
			const inventoryMetricsColumns = [
				{field: 'description', header: labels[2], width: '10%'},
				{field: 'dealerStock', header: labels[3], width: '10%'},
				{field: 'inTransit', header: labels[4], width: '10%'},
				{field: 'inSystem', header: labels[5], width: '10%'},
			].map((col) => ({title: col.header, dataKey: col.field}));

			let vehicleLine = '';
			let pages = 0;
			inventoryMetricsData.forEach(function(value: InventoryMetrics[], key: string) {
				vehicleLine = value[0].vehicleName;
				if (pages > 0) {
					doc.addPage('a4', 'l');
				}
				doc.text([labels[0]+': ' + vehicleLine, labels[1]+': ' + key],30, 30, {align: 'left'});
				const rows = (metrics: InventoryMetrics[]) => {
					const extracted: SimpleInventoryMetrics[] = [];
					metrics.forEach((metric) => {
						const m: SimpleInventoryMetrics = new class implements SimpleInventoryMetrics {
							description: string;
							dealerStock: number;
							inTransit: number;
							inSystem: number;
						}();
						m.description = metric.description;
						m.dealerStock = metric.dealerStock;
						m.inTransit = metric.inTransit;
						m.inSystem = metric.inSystem;
						extracted.push(m);
					});
					return extracted;
				};

				(doc as any).autoTable(inventoryMetricsColumns, rows(value), {
					tableWidth: 'auto',
					startY: 60,
					columnStyles: { text: { cellWidth: 'auto', fontSize: 8}},
					styles: {
						overflow: 'linebreak',
						fontSize: 8
					},
					headStyles :{fillColor : [0, 9, 91]}
				});
				pages++;
			});
			this.createPageNumbers(doc);
			doc.save(this.fileName);
		});
	}
	private buildFileName(vl: string): void {
		this.fileName = vl + '_' + 'Inventory_Metrics_' + DateTime.now().toFormat('MMM-dd-yyyy');
	}
	private createPageNumbers(doc) {
		const pageCount = doc.internal.pages.length;
		for (let i = 0; i < pageCount; i++) {
			doc.setPage(i);
			const currentPage = doc.getCurrentPageInfo().pageNumber;
			doc.setFontSize(12);
			doc.text('page: ' + currentPage + '/' + (pageCount - 1),
				doc.internal.pageSize.width / 2,
				doc.internal.pageSize.height - 10);
		}
		return doc;
	}
	public createWorkbook(): Workbook {
		return new Workbook();
	}
}

export interface SimpleInventoryMetrics {
	description: string;
	dealerStock: number;
	inTransit: number;
	inSystem: number;
}
