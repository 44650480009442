import {HomeComponent} from './components/home/home.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from '@wame/ngx-frf-utilities';
import {AuthenticationGuard, OauthCallbackComponent, OauthTokenGuard} from '@wame/ngx-adfs';
import {WholesaleComponent} from './components/wholesale/wholesale.component';
import {UnauthorizedComponent} from './components/unauthorized/unauthorized.component';
import {DirtyGuard} from './components/unsaved_change_detector/DirtyGuard';
import {SimsPreferencesW2Component} from './components/sims-preferences-w2/sims-preferences-w2.component';

const routes: Routes = [
	{
		path: 'oauth',
		component: OauthCallbackComponent,
		canActivate: [OauthTokenGuard]
	},
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthenticationGuard],
	},
	{
		path: 'wholesale',
		component: WholesaleComponent,
		canActivate: [AuthenticationGuard],
		canDeactivate: [DirtyGuard],
	},
	{
		path: 'w2-wholesale',
		component: WholesaleComponent
	},
	{
		path: 'w2-sims-preferences',
		component: SimsPreferencesW2Component
	},
	{
		path: '',
		redirectTo: '/home',
		pathMatch: 'full'
	},
	{
		path: 'unauthorized',
		component: UnauthorizedComponent
	},
	{
		path: '404',
		component: NotFoundComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
