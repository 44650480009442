import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CalendarEvent} from '../model/calendar-event';

@Injectable({
	providedIn: 'root'
})
export class EventService {
	eventsURL = environment.baseUrl + '/api/v1/events';

	constructor(private http: HttpClient) {
	}

	public fetchEvents(): Observable<CalendarEvent[]> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json',
			}),
		};
		return this.http.get<CalendarEvent[]>(this.eventsURL, httpOptions);
	}
}
