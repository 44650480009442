import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {UserInfoService} from './service/user-info-service';

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	/** string used to hold the url for the skipToMain link */
	skipToMain: string;
	/**	stores the current status of whether this page is embedded in WBDO2 */
	w2Integration: boolean;

	/** constructor for setting up DI in this component */
	constructor(private readonly router: Router,
				private userInfoService: UserInfoService) {
	}

	/** this class requires this method to implement the OnInit interface */
	ngOnInit(): void {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
				this.checkW2EmbedAndSetFlag(event);
			});
	}

	/**
	 * Sets the w2Integration flag based on the navigated route.
	 * If the navigated route includes 'w2', then this page is being opened as an iframe
	 * in WBDO2.
	 * @param event Navigation event to check
	 */
	checkW2EmbedAndSetFlag(event: NavigationEnd) {
		if (event.url.includes('w2')) {
			this.w2Integration = true;
		} else {
			this.w2Integration = false;
		}
		this.userInfoService.setW2Integration(this.w2Integration);
	}

	/**
	 * setSkipLinkUrl takes in a url string and processes whether
	 * the skipToMain link should be updated to use the new value
	 * @param currentUrl the new url to refer to
	 */
	private setSkipLinkUrl(currentUrl: string): void {
		if (!currentUrl.endsWith('#app-content')) {
			this.skipToMain = `${currentUrl}#app-content`;
		}
	}
}
