import {Component, OnInit} from '@angular/core';
import {UserInfoService} from '../../service/user-info-service';
import {Observable} from 'rxjs';

@Component({
	selector: 'app-user-info',
	templateUrl: './user-info.component.html',
	styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
	loggedInUser: Observable<string>;

	constructor(private userInfoService: UserInfoService) {
	}
	ngOnInit() {
		this.loggedInUser = this.userInfoService.getFirstName();
	}
}
