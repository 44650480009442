<div class="grid justify-content-end flex flex-column" style="height: 70px" *ngIf="parentComponent.wholesaleDataExists()">
	<div class="flex gap-2 justify-content-end">
		<div class="pr-7" style="height: 70px">
			<div class="col-8 flex gap-2 p-0">
				<span class="text-md font-semibold" style="display: flex; align-items: center;"> {{ 'wbdo.wholesale.commitment' | translate }} </span>

				<p-button id="save-button" ngDefaultControl [disabled]="!isSaveAllowed || (spinnerService.visibility | async)" [(ngModel)]="vehicleLine"
						  (onClick)="onSaveOrApproveClick(vehicleLine,'SAVE')" label="{{ 'wbdo.wholesale.save' | translate }}"
						  [pTooltip]="saveTooltip" tooltipPosition="bottom"></p-button>
				<p-button id="approve-button" ngDefaultControl [disabled]="!isApproveAllowed || (spinnerService.visibility | async)" [(ngModel)]="vehicleLine"
						  (onClick)="onSaveOrApproveClick(vehicleLine,'COMMIT')" label="{{ 'wbdo.wholesale.approve' | translate }}"
						  [pTooltip]="approveTooltip" tooltipPosition="bottom"></p-button>
			</div>
			<div class="col-8 flex justify-content-end font-italic p-0 pt-2">
				<span class="text-sm" style="color: #2d2d2d" *ngIf="commitmentStatus"> {{ 'wbdo.wholesale.status' | translate }}: {{commitmentStatus}} </span>
			</div>
		</div>
	</div>
</div>
