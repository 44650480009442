import {Component, Input, OnInit} from '@angular/core';
import {Message} from '../../model/message';

@Component({
	selector: 'message-list',
	templateUrl: './message-list.component.html',
	styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {
	@Input() filteredData: Map<string, Message[]>;
	@Input() messageData: Map<string, Message[]>;
	activeCategoryTabIndex: number;
	messageId: string;
	messageCategories: string[];

	ngOnInit(): void {
		this.messageCategories = ['All', 'Vehicle Ordering', 'OTD', 'Wholesale', 'Scheduling', 'Miscellaneous'];
	}

	formatDate(dateTime: string): string {
		return new Date(dateTime).toLocaleDateString([], {year: 'numeric', month: 'long', day: 'numeric'});
	}
}
