<div class="main-body">
	<div>{{ 'wbdo.wholesale.unsavedChangesDialog.unsavedChangesBody' | translate }}</div>
	<div class="p-toolbar-group-end">
		<div class="card grid">
			<div class="col-12 flex flex-wrap gap-3 w-full justify-content-center">
				<p-button class="header-button" label="{{ 'wbdo.wholesale.unsavedChangesDiscard' | translate }}"
						  (onClick)="discardChanges()"></p-button>
				<p-button class="header-button" label="{{ 'wbdo.wholesale.unsavedChangesKeep' | translate }}"
						  (onClick)="stayOnPage()"></p-button>
			</div>
		</div>
	</div>
</div>
