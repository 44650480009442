import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocaleService} from 'src/app/service/locale-service';
import {Subscription} from 'rxjs';
import {UserInfoService} from '../../service/user-info-service';


@Component({
	selector: 'app-locale',
	templateUrl: './locale.component.html',
	styleUrls: ['./locale.component.scss']
})
export class LocaleComponent implements OnInit, OnDestroy {
	langSubscription: Subscription;
	lang: string;
	langs = [];

	constructor(private localeService: LocaleService,
				private userInfoService: UserInfoService) {
	}

	ngOnInit(): void {
		this.loadLangsForDropdown();

		// Establish observable to the service (other components will replicate this subscription)
		this.langSubscription = this.localeService.getLanguage()
			.subscribe((lang) => this.lang = lang);
	}

	// Clean up memory
	ngOnDestroy(): void {
		this.langSubscription.unsubscribe();
	}

	// Method to only be called by this component's HTML
	changeLang(lang: string): void {
		this.userInfoService.setUserSelectedLang(lang);
		this.localeService.updateLanguage(lang);
		this.reloadWindow();
	}

	// To mock this function in jasmine tests
	reloadWindow(): void {
		window.location.reload();
	}

	// Populate the dropdown according to user's location
	loadLangsForDropdown(): void {
		this.userInfoService.getMarket().subscribe(market => {
			this.langs = [];
			this.langs.push({label: 'EN', value: 'en'});
			if (market === 'WANAC') {
				this.langs.push({label: 'FR', value: 'fr'});
			}
			if (market === 'WANAD') {
				this.langs.push({label: 'ES', value: 'es'});
			}
		});
	}
}
