import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SimsPreferencesW3Component} from '../sims-preferences-w3/sims-preferences-w3.component';

/** Angular component that displays header for the application
 */
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	isLincoln!: boolean;
	dealerDetails: MenuItem[];
	navMenuItems: MenuItem[] = [];

	ref: DynamicDialogRef | undefined;

	constructor(private translateService: TranslateService,
				public dialogService: DialogService) {
	}

	ngOnInit() {
		this.dealerDetails = [
			{
				label: 'DealerName - DealerCode',
			}
		];
		this.loadNavMenuItems();
	}

	public loadNavMenuItems(): void {
		this.translateService.onLangChange.subscribe(() => {
			this.navMenuItems = [
				{
					label: this.translateService.instant('wbdo.nav.home'),
					icon: 'pi pi-fw pi-home',
					routerLink: '/home'

				},
				{
					label: this.translateService.instant('wbdo.nav.orders'),
					icon: 'pi pi-fw pi-car',
					items: [
						{
							label: this.translateService.instant('wbdo.nav.newOrder'),
							routerLink: '/new-order',
						}
					]
				},
				{
					label: this.translateService.instant('wbdo.nav.wholesale'),
					icon: 'pi pi-fw pi-clone',
					routerLink: '/wholesale'

				},
				{
					label: this.translateService.instant('wbdo.nav.reports'),
					icon: 'pi pi-fw pi-chart-line',
					items: [
						{
							label: this.translateService.instant('wbdo.nav.stockListing'),
							routerLink: '/stock-listing-report',
						},
						{
							label: this.translateService.instant('wbdo.nav.canceledOrders'),
							routerLink: '/canceled-orders-report',
						},
					]
				},
				{
					label: this.translateService.instant('wbdo.nav.preferences'),
					icon: 'pi pi-fw pi-cog',
					items: [
						{
							label: this.translateService.instant('wbdo.sims.shortHeader'),
							command: (event) => this.displaySimsPreferences(),
						},
					]
				},
				{
					label: this.translateService.instant('wbdo.nav.help'),
					icon: 'pi pi-fw pi-question-circle'
				}
			];
		});
	}

	displaySimsPreferences(): void {
		let simsMessage: string;
		this.translateService.get('wbdo.sims.header').subscribe(message => simsMessage = message);
		this.ref = this.dialogService.open(SimsPreferencesW3Component, {
			header: simsMessage,
			width: '48%',
			maximizable: false,
			closeOnEscape: true,
			closable: false,
			styleClass: 'sims-preferences-dialog',
			data: {
				headerHtml: '<span class="header-subtitle">Set your preferences for OTD order generation</span>'
			}
		});
		this.ref.onClose.subscribe();
	}

}
