import {Component, OnInit} from '@angular/core';
import {Dealer} from '../../service/init-service';
import {UserInfoService} from '../../service/user-info-service';

@Component({
	selector: 'salescode-component',
	templateUrl: './salescode-component.html',
	styleUrls: ['./salescode-component.scss']
})
export class SalescodeComponent implements OnInit {
	visible = false;

	selectedSalesCode: Dealer;
	selectedSalesCodeValue: string;
	salesCodes: Dealer[];
	allSalesCode: boolean;

	constructor(private userInfoService: UserInfoService) {

	}
	ngOnInit(): void {
		this.userInfoService.getSalesCodes().subscribe(salesCodes => {
			this.salesCodes = salesCodes || [];
		});
		if (this.salesCodes.find(d => (d.code === 'ALL'))) {
			this.allSalesCode = true;
		}
	}
}
