import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class MessageService {
	messagingURL = environment.baseUrl + '/api/v1/messages';
	attachmentURL = environment.baseUrl + '/api/v1/attachment';

	constructor(private http: HttpClient) {
	}

	public fetchMessages(status: string = 'ACTIVE'): Observable<Map<string, Object[]>> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json',
			}),
			params: new HttpParams()
				.set('status', status)
		};
		return this.http.get<Map<string, Object[]>>(this.messagingURL, httpOptions);
	}

	public fetchAttachment(uri: string): Observable<Blob> {
		const encodedUri = encodeURIComponent(uri);
		return this.http.get(this.attachmentURL, {
			params: new HttpParams()
				.set('uri', encodedUri),
			responseType: 'blob'
		});
	}
}
