import { Injectable } from '@angular/core';
import { DirtyCheckGuard } from '@ngneat/dirty-check-forms';
import {Observable} from 'rxjs';
import {UnsavedChangesComponent} from '../unsaved-changes/unsaved-changes.component';
import {TranslateService} from '@ngx-translate/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Injectable({ providedIn: 'root'})
export class DirtyGuard extends DirtyCheckGuard {

	unsavedRef: DynamicDialogRef | undefined;
	constructor(private translate: TranslateService,
				private dialogService: DialogService) {
		super();
	}

	confirmChanges(): Observable<boolean> | boolean {
		let unsavedMessage: string;
		this.translate.get('wbdo.wholesale.unsavedChangesDialog.unsavedChangesHeader').subscribe(message => unsavedMessage = message);
		this.unsavedRef = this.dialogService.open(UnsavedChangesComponent, {
			header: unsavedMessage,
			width: '32%',
			maximizable: false,
			styleClass: 'unsaved-changes-dialog'
		});
		return this.unsavedRef.onClose;
	}
}
