import {Component} from '@angular/core';

/**
 * This component acts as a 404 page. If an invalid url is entered and there are no routes that match, this component
 * will be loaded instead.
 */
@Component({
	selector: 'not-found',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
}
