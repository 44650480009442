import {Injectable} from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize, Observable, of, tap} from 'rxjs';
import {SpinnerService} from '../../service/spinner-service';
import {UserInfoService} from '../../service/user-info-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private router: Router,
				private route: ActivatedRoute,
				private spinnerService: SpinnerService,
				private userInfoService: UserInfoService) { }

	service_count = 0; // initialize the counter.


	private handleAuthError(err: HttpErrorResponse): Observable<any> {
		//handle your auth error or rethrow
		this.spinnerService.hide();
		if (err.status === 401 || err.status === 403) {
			this.router.navigateByUrl('/unauthorized');
			return of(err.message);
		} else {
			return of(err.message);
		}
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let authReq = req;
		this.service_count++; // increment the count for each intercepted http request.
		this.spinnerService.show();

		if (req.headers.get('Authorization') === 'Bearer null' || !req.headers.get('Authorization')) {
			this.userInfoService.getAccessToken().subscribe(token => {
				authReq = req.clone(
					{headers: req.headers.set('Authorization', 'Bearer ' + token)});
			});
		}

		// catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
		return next.handle(authReq)
			.pipe(tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
				} else {
					this.spinnerService.show();
				}
			}, (error) => {
				this.handleAuthError(error);
			})).pipe(finalize(() => {
				this.service_count--;
				if (this.service_count === 0) {
					this.spinnerService.hide();
				}
			}));
	}
}
