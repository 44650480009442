import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {WholesaleService} from '../../service/wholesale-service';
import {ControlTarget} from '../../model/control-target';

@Component({
	selector: 'control-validation',
	templateUrl: './control-validation.component.html',
	styleUrls: ['./control-validation.component.scss']
})
export class ControlValidationComponent implements OnInit {
	wholesaleControl: ControlTarget[];

	constructor(public wholesaleService: WholesaleService,
				public ref: DynamicDialogRef,
				public config: DynamicDialogConfig) { }

	ngOnInit(): void {
		if (this.config !== undefined && this.config !== null) {
			this.wholesaleControl = this.config.data !== undefined ? this.config.data.wholesaleControlTargets : [];
		}
	}

}
