import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {SimsPreferencesComponent} from '../sims-preferences/sims-preferences.component';
import {SimsPreferencesService} from '../../service/sims-preferences-service';
import {UserInfoService} from '../../service/user-info-service';
import {MessageService} from 'primeng/api';

@Component({
	selector: 'sims-preferences-w2-footer',
	templateUrl: './sims-preferences-w3.component.html',
	styleUrls: ['./sims-preferences-w3.component.scss'],
	providers: [SimsPreferencesComponent, MessageService]
})
export class SimsPreferencesW3Component implements OnInit {
	dealerCode: string;
	market: string; // "USA"
	division: string; // "FORD"

	updatedUser: string;
	updatedTimestamp: string;

	errorMessage: string;
	checked: false;

	constructor(public ref: DynamicDialogRef,
				private translate: TranslateService,
				private simsPreferencesService: SimsPreferencesService,
				private userInfoService: UserInfoService,
				private messageService: MessageService) { }

	ngOnInit(): void {
		this.userInfoService.getDealerCode().subscribe(dealerCode => {
			this.dealerCode = dealerCode;
		});
		this.userInfoService.getDivision().subscribe(division => {
			this.division = division;
		});
		this.userInfoService.getMarket().subscribe(market => {
			this.market = market;
			switch (this.market) {
			case 'WANAB':
				this.market = 'USA';
				break;
			case 'WANAC':
				this.market = 'CAN';
				break;
			}
		});

		this.simsPreferencesService.getSimsData().subscribe({next: simsData => {
			this.updatedTimestamp = simsData.updatedTimeStamp;
			this.updatedUser = simsData.updateBy;
		}});
	}

	submitSimsPreferences(): void {

		this.simsPreferencesService.postSimsPreferencesData(this.dealerCode).subscribe({
			next: () => {
				this.translate.get('wbdo.sims.simsSubmit').subscribe(message => this.showMessageServiceInfo(message));
				this.closeDialog();
			},
			error: () => {
				this.translate.get('wbdo.sims.simsError').subscribe(message => this.showMessageServiceError(message));
			}
		});
	}

	showMessageServiceError(message: string, severity?: string): void {
		this.messageService.clear();
		this.messageService.add({
			key: 'sims-preferences-error',
			severity: severity ? severity : 'error',
			summary: severity ? severity.charAt(0).toUpperCase() + severity.slice(1) : 'Error',
			sticky: true,
			detail: message
		});
		this.errorMessage = message;
	}
	showMessageServiceInfo(message: string): void {
		this.messageService.clear();
		this.messageService.add({
			key: 'sims-preferences-submit',
			severity: 'info',
			summary: 'Success Message',
			sticky: true,
			detail: message
		});
		this.errorMessage = message;
	}

	closeDialog(): void {
		this.ref.close();
	}

}
