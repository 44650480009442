import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WholesaleConfig} from '../model/wholesale-config';
import {WholesaleVehicle} from '../model/wholesale-vehicle';
import {WholesaleStatus} from '../model/wholesale-status';
import {SpecificationCommitment} from '../model/specification-commitment';
import {CommitResponse} from '../model/commit-response';
import {UserInfoService} from './user-info-service';

@Injectable({
	providedIn: 'root'
})
export class WholesaleService {
	wholesaleURL = environment.baseUrl + '/api/v1/wholesale';

	constructor(private http: HttpClient,
				private userInfoService: UserInfoService) {
	}

	public fetchVehicles(market: string, lincoln: string, dealerCode: string): Observable<Object> {
		const httpOptions = {
			params: new HttpParams()
				.set('market', market)
				.set('lincoln', lincoln)
		};
		return this.http.get<Object>(this.pathWithDealerCode(dealerCode, 'vehicles'), httpOptions);
	}

	public fetchControlValidation(dealerCode: string,
								  market: string,
								  ptvl: string,
								  modelYear: string,
								  regionalAllocationVehicleLineCode: string,): Observable<Object[]> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json'
			}),
			params: new HttpParams()
				.set('market', market)
				.set('ptvl', ptvl)
				.set('modelYear', modelYear)
				.set('regionalAllocationVehicleLineCode', regionalAllocationVehicleLineCode)
				.set('locale', this.getLocale())
		};
		return this.http.get<Object[]>(this.pathWithDealerCode(dealerCode, 'controls'), httpOptions);
	}

	public fetchWholesaleDetails(dealerCode: string,
								 market: string,
								 vehicle: WholesaleVehicle,
								 division: string,
								 region: string,
								 blacklbl: boolean): Observable<WholesaleConfig> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json'
			}),
			params: new HttpParams()
				.set('locale', this.getLocale())
				.set('market', market)
				.set('vehicleLine', vehicle.ptvl)
				.set('modelYear', Number(vehicle.modelYear))
				.set('division', division)
				.set('region', region)
				.set('allocationCode', vehicle.regAllocVlCode)
				.set('catalogId', vehicle.catalogId)
				.set('isBlackLabel', blacklbl.toString())
		};

		return this.http.get<WholesaleConfig>(this.pathWithDealerCode(dealerCode, 'config'), httpOptions);
	}

	fetchInventoryMetrics(dealerCode: string, market: string,  division: string, region: string,
						  vehicleLine: string, vehicleName: string, allocationCode: string): Observable<Object> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json'
			}),
			params: new HttpParams()
				.set('market', market)
				.set('vehicleLine', vehicleLine)
				.set('vehicleName', vehicleName)
				.set('division', division)
				.set('region', region)
				.set('allocationCode', allocationCode)
				.set('locale', this.getLocale())
		};
		return this.http.get<Object>(this.pathWithDealerCode(dealerCode, 'metrics/info'), httpOptions);
	}

	fetchInventoryMetricsEnable(dealerCode: string, vehicleLine: string): Observable<Object> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json'
			}),
			params: new HttpParams()
				.set('vehicleLine', vehicleLine)
		};
		return this.http.get<Object>(this.pathWithDealerCode(dealerCode, 'metrics'), httpOptions);
	}

	fetchWholesaleStatus(dealerCode: string, market: string): Observable<WholesaleStatus> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json'
			}),
			params: new HttpParams()
				.set('market', market)
				.set('locale', this.getLocale())
		};
		return this.http.get<WholesaleStatus>(this.pathWithDealerCode(dealerCode, 'status'), httpOptions);
	}


	public postWholesaleCommitments(action: string, dealerCode: string,
		specificationCommitmentList: SpecificationCommitment[],
		productionCycle: string,
		market: string,
		vehicle: WholesaleVehicle,
		volumeLevelCommitmentCount: number): Observable<CommitResponse> {

		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json'
			}),
			params: new HttpParams()
				.set('locale', this.getLocale())
		};

		const postObject = {
			commitmentStatus: action,
			specificationCommitmentList,
			productionCycle,
			vehicleInfo: {
				wersMarket: market,
				modelYear: Number(vehicle.modelYear),
				ptvl: vehicle.ptvl,
				regionalAllocationVehicleLineCode: vehicle.regAllocVlCode,
				regionalAllocationVehicleLineDesc: vehicle.name,
				regionalAllocationVehicleLineVolumeLevelCommitmentCount: volumeLevelCommitmentCount
			}
		};
		return this.http.post<CommitResponse>(this.pathWithDealerCode(dealerCode, 'commit'),
			postObject,
			httpOptions);
	}

	/**
	 * Constructs a URL with the sales code path variable and path
	 * @param dealerCode Sales code to insert as path variable
	 * @param path Endpoint after sales code
	 * @returns Constructed URL
	 */
	private pathWithDealerCode(dealerCode: string, path: string): string {
		return `${this.wholesaleURL}/${dealerCode}/${path}`;
	}

	/**
	 * Get locale from user info service, or default to "en_US"
	 * @returns Locale code
	 */
	private getLocale(): string {
		let locale: string;
		this.userInfoService.getLocale().subscribe(loc => locale = loc || 'en_US');
		return locale;
	}
}
