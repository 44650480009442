import {Component, OnInit} from '@angular/core';
import {SimsPreferencesComponent} from '../sims-preferences/sims-preferences.component';
import {SimsPreferencesService} from '../../service/sims-preferences-service';
import {UserInfoService} from '../../service/user-info-service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';

@Component({
	selector: 'sims-preferences-w2',
	templateUrl: './sims-preferences-w2.component.html',
	styleUrls: ['./sims-preferences-w2.component.scss'],
	providers: [SimsPreferencesComponent]
})
export class SimsPreferencesW2Component implements OnInit {
	dealerCode: string;
	market: string;
	division: string;

	updatedTimestamp: string;
	updatedUser: string;
	message: string;
	checked = false;

	constructor(private simsPreferencesService: SimsPreferencesService,
				private userInfoService: UserInfoService,
				private  translate: TranslateService,
				private  messageService: MessageService) { }

	ngOnInit(): void {
		this.userInfoService.getDealerCode().subscribe(dealerCode => {
			this.dealerCode = dealerCode;
		});
		this.userInfoService.getDivision().subscribe(division => {
			this.division = division;
		});
		this.userInfoService.getMarket().subscribe(market => {
			switch (market) {
			case 'WANAB' || 'USA':
				this.market = 'USA';
				break;
			case 'WANAC'|| 'CAN':
				this.market = 'CAN';
				break;
			default:
				this.market = market;
			}
		});

		this.simsPreferencesService.getSimsData().subscribe({next: simsData => {
			this.updatedTimestamp = simsData.updatedTimeStamp;
			this.updatedUser = simsData.updateBy;
		}});

		if (this.userInfoService.getW2Integration()) {
			document.getElementById('buttonsSIMS').style.paddingRight = '77px';
		}
	}

	submitSimsPreferences(): void {
		this.simsPreferencesService.postSimsPreferencesData(this.dealerCode).subscribe({
			next: (res) => {
				console.log(res);
				this.translate.get('wbdo.sims.simsSubmit').subscribe(message => this.showMessageService(message, 'success'));
				this.cancelButton();
			},
			error: () => {
				this.translate.get('wbdo.sims.simsError').subscribe(message => this.showMessageService(message, 'error'));
			}
		});

	}

	showMessageService(message: string, severity?: string): void {
		this.messageService.clear();
		this.messageService.add({
			key: 'sims-preferences-message',
			severity: severity ? severity : 'error',
			summary: severity ? severity.charAt(0).toUpperCase() + severity.slice(1) : 'Error',
			sticky: true,
			detail: message
		});
		this.message = message;
	}

	cancelButton(): void {
		window.parent.postMessage(JSON.stringify({status: 'Cancelled', data: null}), '*');
	}

}
