	<div id="app-branding" class="grid">
		<div class="col-.5">
			<div id="app-logo" routerLink="/home" class="logo-items">
				<div id="app-icon">
					<img alt="Ford Oval" height="44px" src="assets/image/FordOval.png" width="113px"/>
				</div>
			</div>
		</div>
		<div class="col-.5">
			<div class="flex flex-wrap gap-3">
				<div class="flex align-items-center">
					<span id="ford" class="input-switch-label">Ford&nbsp;</span>
					<p-inputSwitch  class="input-switch-sm" [(ngModel)]="isLincoln"></p-inputSwitch>
					<span id="lincoln" class="input-switch-label">&nbsp;Lincoln</span>
				</div>
			</div>
		</div>
		<div class="col-5">
			<p-menubar id="app-menubar" [model]="navMenuItems">
			</p-menubar>
		</div>
		<div class="col-.5">
		</div>
		<div class="col-3">
			<salescode-component></salescode-component>
		</div>
		<div class="col-.5">
			<app-user-info class="logo-items"></app-user-info>
		</div>
		<div class="col-.5">
			<app-locale></app-locale>
		</div>
	</div>
