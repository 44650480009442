<p-tabView [(activeIndex)]="activeCategoryTabIndex" class="category-tabs">
	<ng-container *ngFor="let category of messageCategories">
		<p-tabPanel [header]="category">
			<ng-container *ngIf="filteredData.get(category)?.length > 0; else noMessages">
				<p-table [value]="filteredData.get(category)"
						 [rows]="10"
						 [paginator]="true"
						 [scrollable]="true"
						 [showCurrentPageReport]="true"
						 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
						 scrollHeight="70vh"
						 [rowsPerPageOptions]="[10, 25, 50]"
				>
					<ng-template pTemplate="body" let-message let-i="rowIndex">
						<tr style="display:block">
							<div class="message-in-list"
								 [ngClass]="{'highlight': messageId === message.id}">
								<div class="flex">
									<div class="flex gap-2 message-title-date-container">
										<div *ngIf="message.getImportant()">
											<i class="pi pi-star-fill starIcon"></i>
										</div>
										<div class="message-title">{{ message.getEnContent().getTitle() }}</div>
										<div class="message-date">{{ formatDate(message.getStartTimestamp()) }}</div>
									</div>
								</div>
								<div class="message-content">{{ message.getEnContent().getBody() }}</div>
								<div *ngIf="message.getEnContent().getAttachmentName() !== undefined"
									 class="flex gap-2 download-attachment">
									<i class="pi pi-paperclip"></i>
									<div class="message-attachment">{{ message.getEnContent().getAttachmentName() }}</div>
								</div>
								<div *ngIf="i === filteredData.get(category).length - 1; else divider">
									<hr class="bottom-hr">
								</div>
								<ng-template #divider>
									<hr>
								</ng-template>
							</div>
						</tr>
					</ng-template>
				</p-table>
			</ng-container>
			<ng-template #noMessages>
				<div class="no-messages">{{ 'wbdo.home.noMessages' | translate }}</div>
			</ng-template>
		</p-tabPanel>
	</ng-container>
</p-tabView>
