import {Component, Input} from '@angular/core';

@Component({
	selector: 'wholesale-banner',
	templateUrl: './wholesale-banner.component.html',
	styleUrls: ['./wholesale-banner.component.scss']
})
export class WholesaleBannerComponent {
	@Input() statusText: string;
	@Input() statusColor: string;
	@Input() statusEndDate: string;

}
