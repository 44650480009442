<div class="main-body p-0">
	<div class="inventory-metrics-card-header flex justify-content-between gap-3 p-3 text-xl" style="min-width: 50%; align-items: center;">
		<div class="inventory-metrics-header-left">
			<div id="imch-vehicle-line" class="imch-label inline">{{ 'wbdo.wholesale.vehicleLine' | translate }}:</div>
			<div class="text-base font-medium inline pl-1">{{inventoryMetricsData.get(modelYear)[0].vehicleName}}</div>
			<div class="imch-label inline pl-2">{{ 'wbdo.wholesale.inventoryMetricsDialog.modelYear' | translate }}:</div>
			<div *ngFor="let my of modelYears" class="align-items-center inline pl-1 pr-2">
				<p-radioButton name="my" [value]=my [(ngModel)]="modelYear" id="modelYear_{{my}}"></p-radioButton>
				<label for="modelyear_{{my}}}" class="text-base ml-2 font-medium">{{my}}</label>
			</div>
		</div>
		<div id="inventory-metrics-export" class="inventory-metrics-header-right">
			<p-menu #immenu [model]="inventoryMetricsDownloadData" [popup]="true" appendTo="body"></p-menu>
			<p-button class="header-button" type="button" label="Export"
					  (onClick)="immenu.toggle($event)"
					  icon="pi pi-chevron-down" iconPos="right"></p-button>
		</div>
	</div>
	<p-table class="inventory-metrics-table" #dt1 styleClass="p-datatable-striped p-datatable-gridlines"
			 [value]="inventoryMetricsData.get(modelYear)">
		<ng-template pTemplate="header">
			<tr>
				<th pSortableColumn="description" style='width: 15%;' scope="col">
					{{ 'wbdo.wholesale.offering' | translate }}
					<p-sortIcon field="description"></p-sortIcon>
				</th>
				<th class="text-center" style='width: 15%' scope="col">{{ 'wbdo.wholesale.inventoryMetricsDialog.inStock' | translate }}</th>
				<th class="text-center" style='width: 15%' scope="col">{{ 'wbdo.wholesale.inventoryMetricsDialog.inTransit' | translate }}</th>
				<th class="text-center" style='width: 15%' scope="col">{{ 'wbdo.wholesale.inventoryMetricsDialog.inSystem' | translate }}</th>
			</tr>
			<tr>
				<td style='width: 15%'> </td>
				<td class="text-center font-semibold" style='width: 15%;'>{{ 'wbdo.wholesale.total' | translate }}: {{getMetricsTotal('dealerStock')}}</td>
				<td class="text-center font-semibold" style='width: 15%;'>{{ 'wbdo.wholesale.total' | translate }}: {{getMetricsTotal('inTransit')}}</td>
				<td class="text-center font-semibold" style='width: 15%;'>{{ 'wbdo.wholesale.total' | translate }}: {{getMetricsTotal('inSystem')}}</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-metrics>
			<tr>
				<td class="pl-3" style='width: 15%;'>{{metrics.description}}</td>
				<td style='width: 15%; text-align: center'>{{metrics.dealerStock}}</td>
				<td style='width: 15%; text-align: center'>{{metrics.inTransit}}</td>
				<td style='width: 15%; text-align: center'>{{metrics.inSystem}}</td>
			</tr>
		</ng-template>
	</p-table>
</div>
