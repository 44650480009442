<p-toast key="sims-preferences-error"></p-toast>
<p-toast key="sims-preferences-submit"></p-toast>
<sims-preferences [dealerCode]="this.dealerCode"
				  [market]="this.market"
				  [division]="this.division">
</sims-preferences>
<tr>
	<div class="field-checkbox flex flex-row gap-2 acknowledge-and-submit">
		<p-checkbox [(ngModel)]="checked" [binary]="true" inputId="binary" class="flex"></p-checkbox>
		<label style="max-width: 467px; font-weight: 400" class="flex text-xs" for="binary">{{'wbdo.sims.acknowledgementNotice' | translate }}</label>
		<div class="flex gap-1 justify-content-end align-items-end">
			<td>
				<button pButton pRipple type="button" class="p-button-outlined" (click)='this.ref.close()'>{{'wbdo.sims.cancel' | translate }}</button>
			</td>
			<td>
				<button pRipple type="button" style="min-width: 78px" [disabled]="!checked" (click)=submitSimsPreferences() id="ok-button" pButton class="justify-content-center">{{'wbdo.sims.ok' | translate }}</button>
			</td>

		</div>
	</div>
</tr>
<div class="last_updated flex justify-content-end align-items-center" style="min-height: 2rem" [hidden]="!this.updatedUser">
	{{'wbdo.sims.lastUpdate' | translate }}
	{{this.updatedUser}}, {{this.updatedTimestamp}}
</div>

