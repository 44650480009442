<div id="wholesale-status-banner" class="display-banner"  style="border-bottom: 0.5rem {{statusColor}} solid;"  *ngIf="statusText">
	<i class="pi pi-info-circle text-xl" style="color: #1700F4;"></i>
	<div class="text-xl" style="margin-left: 0.5rem; display: flex;">
		<span style="margin-right: 0.5rem;">{{ 'wbdo.wholesale.status' | translate }}: </span>
	</div>
	<div class="text-xl" style="color:{{statusColor}}; margin-left: 0.25rem;">
		{{statusText}}
	</div>
	<div class="text-lg" style="margin-left: 0.5rem">
		{{statusEndDate}}
	</div>
</div>
