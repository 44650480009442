<ng-container *ngIf="events !== undefined && events.length > 0; else noEvents">
	<p-table [value]="events"
			 [rows]="10"
			 [scrollable]="true"
			 scrollHeight="300px"
	>
		<ng-template pTemplate="body" let-event let-i="rowIndex">
			<ng-container *ngIf="checkDateDiff(i)">
				<div class="date-container">
					{{formatDate(event.start)}}
				</div>
			</ng-container>
			<tr style="display:block">
				<div class="event-in-list">
					<div class="flex">
						<div class="event-container">
							<div class="title">{{getTitle(event,'en')}}</div>
							<div class="body">{{getBody(event,'en')}}</div>
						</div>
						<div style="padding-top: 25px; padding-right: 40px; position: absolute; right: 0;" *ngIf="event.content.length > 1">
							<p-button icon="pi pi-ellipsis-h" styleClass="p-button-text" (onClick)="expandEvent(event.id)"></p-button>
						</div>
					</div>
					<div id={{event.id}} style="display:none!important" class="event-container">
						<div *ngIf="getContent(event, 'fr')">
							<hr/>
							<div class="title">{{getTitle(event, 'fr')}}</div>
							<div class="body">{{getBody(event, 'fr')}}</div>
						</div>
						<div *ngIf="getContent(event, 'es')">
							<hr/>
							<div class="title">{{getTitle(event, 'es')}}</div>
							<div class="body">{{getBody(event, 'es')}}</div>
						</div>
					</div>
				</div>
			</tr>
		</ng-template>
	</p-table>
</ng-container>
<ng-template #noEvents>
	<div class="no-events">{{ 'wbdo.home.noEvents' | translate }}</div>
</ng-template>
