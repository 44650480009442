import {Component} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
	selector: 'unsaved-changes',
	templateUrl: './unsaved-changes.component.html'
})
export class UnsavedChangesComponent {
	constructor(private dialogRef: DynamicDialogRef){}

	discardChanges(): void {
		this.dialogRef.close(true);
	}

	stayOnPage(): void {
		this.dialogRef.close(false);
	}

}
