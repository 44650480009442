import { Component, OnInit } from '@angular/core';
import {Message} from '../../model/message';
import {CalendarEvent} from '../../model/calendar-event';
import {MessageService} from '../../service/message-service';
import {EventService} from '../../service/event-service';

/**
 * Home page component. Contains the entire contents of the app's Home tab.
 */
@Component({
	selector: 'home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	/** Messages used to populate a child MessageList component */
	filteredMessages: Map<string, Message[]>;
	/** Messages used to populate a child MessageList component  */
	messageData: Map<string, Message[]>;
	/** List of calendar events, used to populate EventCalendar and EventList components */
	events: CalendarEvent[];
	/** Show active or archived messages */
	messageStatus: string;

	/** Construct HomeComponent */
	constructor(private messageService: MessageService,
				private eventService: EventService) {

	}

	/** Initialize HomeComponent, pulling in message and event data. */
	ngOnInit(): void {
		this.messageStatus = 'Active';
		this.filteredMessages = new Map();
		this.messageData = new Map();
		this.eventService.fetchEvents().subscribe(this.processEvents);
		this.messageService.fetchMessages().subscribe();
	}

	/** Sort events by start date */
	processEvents = (response: CalendarEvent[]): void => {
		this.events = response['result'].sort(this.sortEventsByStartDate);
	};

	/** Sorter for events which compares their start dates in milliseconds since the epoch. */
	sortEventsByStartDate(a: CalendarEvent, b: CalendarEvent): number {
		const dateA = new Date(a.start).getTime();
		const dateB = new Date(b.start).getTime();
		return dateA - dateB;
	}

}
