import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SimsPreferencesService, VehicleLine} from '../../service/sims-preferences-service';

@Component({
	selector: 'sims-reset-default',
	templateUrl: './sims-reset-default.component.html',
	styleUrls: ['./sims-reset-default.component.scss']
})
export class SimsResetDefaultComponent implements OnInit {
	@Input() vehicleList!: VehicleLine[];
	@Output() resetEvent = new EventEmitter<boolean>();

  	constructor(private simsPreferencesService: SimsPreferencesService) { }

	ngOnInit(): void {

	}

	resetDefaultMethod() {
		this.vehicleList.forEach(vehicle => {
			vehicle.standardConfig = false;
		});
		this.simsPreferencesService.toggleAllConfig(false);
	}
}
