/**This file contains a constant object that has properties that are necessary
 * for the build found in the `dev` configuration in `angular.json`.
 */

export const environment = {
	production: true,
	baseUrl: 'https://api.qa01e.gcp.ford.com/navos-wbdo-service',
	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize/wia',
	openidClientId: 'fc:pcf:qa:wbdo:native',
	openidResource: 'fc:pcf:qa:wbdo:web'
};
