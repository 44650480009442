import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {PrimeNGConfig} from 'primeng/api';
import {UserInfoService} from './user-info-service';

@Injectable({
	providedIn: 'root'
})
export class LocaleService {

	private languageSubject$: Subject<string> = new BehaviorSubject<string>('en');
	language$: Observable<string> = this.languageSubject$.asObservable();

	constructor(public primeNGConfig: PrimeNGConfig,
				public translate: TranslateService,
				private userInfoService: UserInfoService) {
		this.primeNGConfig.setTranslation({
			accept: 'Accept',
			reject: 'Cancel',
			//translations
		});
		this.translate.addLangs(['en', 'fr', 'es']);
		this.translate.setDefaultLang('en');
	}

	// Starter language function only called on app init
	public async setLanguage(): Promise<void> {
		this.userInfoService.getMarket().subscribe({
			next: async market => {
				const userSelectedLang = this.userInfoService.getUserSelectedLang();
				const wbdo2Locale: string = await firstValueFrom(this.userInfoService.getLocale());
				const wb2Integration: boolean = await firstValueFrom(this.userInfoService.getW2Integration());
				if (wb2Integration && wbdo2Locale && wbdo2Locale.length > 0) {
					// first check incoming locale from wbdo2
					this.updateLanguage(wbdo2Locale);
				} else if (this.userInfoService.getUserSelectedLang()) {
					// second check if user has selected a lang via our dropdown
					this.updateLanguage(userSelectedLang);
				} else if (market) {
					// default language to the market's language
					if (market === 'WANAC') {
						this.updateLanguage('fr');
					} else if (market === 'WANAD') {
						this.updateLanguage('es');
					} else {
						// lastly, default to English
						this.updateLanguage('en');
					}
				}
				// if market does not exist, local-service defaults to English via constructor
			}
		});
	}

	public updateLanguage(lang: string): void {
		if (lang.includes('fr')) {
			this.languageSubject$.next('fr');
			this.translate.use('fr');
		} else if (lang.includes('es')) {
			this.languageSubject$.next('es');
			this.translate.use('es');
		} else {
			this.languageSubject$.next('en');
			this.translate.use('en');
		}
		this.translate.get('primeng').subscribe(data =>
			this.primeNGConfig.setTranslation(data));
	}

	/** gets the locale stored in this service
	 *
	 * @returns the locale cast as an observable to be subscribed to by other components
	 */
	public getLanguage(): Observable<string> {
		return this.language$;
	}

	public exists(A: any): boolean {
		return A !== undefined && A !== null;
	}
}
