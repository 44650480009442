import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SimsPreferences, SimsPreferencesService, VehicleLine} from '../../service/sims-preferences-service';
import {MessageService} from 'primeng/api';
import {UserInfoService} from '../../service/user-info-service';

export interface Column {
	field: string;
	header: string;
}

@Component({
  	selector: 'sims-preferences',
  	templateUrl: './sims-preferences.component.html',
  	styleUrls: ['./sims-preferences.component.scss'],
	providers: [MessageService]
})
export class SimsPreferencesComponent implements OnInit {
	@Input() dealerCode: string;
	@Input() market: string;
	@Input() division: string;

	columnHeaders!: Column[];

	vehicleList!: VehicleLine[];
	errorMessage = '';
	isW2Integration: boolean;

  	constructor(
		private translate: TranslateService,
		public simsPreferencesService: SimsPreferencesService,
		private messageService: MessageService,
		private userInfoService: UserInfoService) {
	}

  	ngOnInit(): void {
		this.getSimsPreferencesData();
		this.simsPreferencesService.getSimsData().subscribe({next: simsData => this.vehicleList = simsData.vehicleLines});
		this.userInfoService.getW2Integration().subscribe(isW2 => this.isW2Integration = isW2);
	}

	public getSimsPreferencesData(): void {
		this.simsPreferencesService.fetchSimsPreferencesData(this.dealerCode, this.market, this.division).subscribe({
			next: (res: SimsPreferences) => {
				this.simsPreferencesService.setSimsData(res);
			},
			error: (err) => {
				this.translate.get('wbdo.wholesale.GeneralError').subscribe(message => this.showMessageServiceError(message));
			}
		});
	}

	showMessageServiceError(message: string, severity?: string): void {
		this.messageService.clear();
		this.messageService.add({
			severity: severity ? severity : 'error',
			summary: severity ? severity.charAt(0).toUpperCase() + severity.slice(1) : 'Error',
			sticky: true,
			detail: message
		});
		this.errorMessage = message;
	}
}
