import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {UserInfoService} from './user-info-service';
import {LocaleService} from './locale-service';

@Injectable({
	providedIn: 'root'
})
export class InitService {
	authorizationURL = environment.baseUrl + '/api/v1/authorization';
	dealerCode: string;

	constructor(private http: HttpClient, private readonly router: Router,
				private userInfoService: UserInfoService,
				private localeService: LocaleService) {
	}

	init() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe({
				next: () => {
					let tokenInfo: TokenInfo;
					this.userInfoService.getStrAccessToken().subscribe({
						next: (strAccessToken: string) => {
							try {
								tokenInfo = JSON.parse(strAccessToken);
							} catch (e) {}
						}
					});
					this.extractUrlParameters();
					this.localeService.setLanguage();
					this.setUserInfo(tokenInfo);

					const httpOptions = {
						headers: new HttpHeaders({
							'content-type': 'application/json',
						}),
					};
					return this.http.get<AuthorizationResponse>(this.authorizationURL, httpOptions)
						.subscribe({
							next: (response: AuthorizationResponse)=> {
								this.userInfoService.setUserInfo(JSON.stringify(response.userInfo));
								this.userInfoService.setPermissions(response.permissions);
								this.userInfoService.setSalesCodes(response.salesCodes);
							}
						});
				}
			});
	}

	setUserInfo(tokenInfo: TokenInfo) {
		if (tokenInfo) {
			const firstName = tokenInfo.givenName;
			this.userInfoService.setFirstName(firstName);
		}
	}

	// mock this function in tests
	retrieveSearchParams(): URLSearchParams {
		return new URLSearchParams(window.location.search);
	}

	extractUrlParameters() {
		const urlParams = this.retrieveSearchParams();
		if (urlParams.get('dealerCode')) {
			this.userInfoService.setDealerCode(urlParams.get('dealerCode'));
			this.dealerCode = urlParams.get('dealerCode');
		} else {
			this.userInfoService.setDealerCode('F81333');
			this.dealerCode = 'F81333';
		}
		this.userInfoService.setMarket(urlParams.get('market') || 'WANAB');
		if (urlParams.get('market')) {
			this.userInfoService.setMarket(urlParams.get('market'));
		} else {
			this.userInfoService.setMarket('WANAB');
		}
		if (urlParams.get('lincoln')) {
			this.userInfoService.setLincoln(urlParams.get('lincoln'));
		} else {
			this.userInfoService.setLincoln('false');
		}
		if (urlParams.get('region')) {
			this.userInfoService.setRegion(urlParams.get('region'));
		} else {
			this.userInfoService.setRegion('48');
		}
		if (urlParams.get('division')) {
			this.userInfoService.setDivision(urlParams.get('division'));
		} else {
			this.userInfoService.setDivision('F');
		}
		// do not set locale default
		// if no locale is set here, language default will be set later
		if (urlParams.get('locale') && urlParams.get('locale').length === 5) {
			this.userInfoService.setLocale(urlParams.get('locale'));
		}

		if (urlParams.get('accessToken'))  {
			this.userInfoService.setAccessToken(urlParams.get('accessToken'));
		}
		else if (urlParams.get('access_token')) {
			this.userInfoService.setAccessToken(urlParams.get('access_token'));
		}
		this.userInfoService.setIsBlackLabel(urlParams.get('lbldealer') === 'true' ? true : false);
	}

}


export interface User {
	id: string;
	firstName: string;
	lastName: string;
	siteCode: string;
}

export interface AuthorizationResponse {
	userInfo: User;
	salesCodes: Dealer[];
	permissions: Permissions[];
}

export interface Dealer {
	code: string;
	name: string;
	label: string;
}

export interface Permissions {
	salesCode: string;
	viewWholesale: boolean;
	saveWholesale: boolean;
	commitWholesale: boolean;
	somsWholesale: boolean;
}

export interface TokenInfo {
	aud: string;
	iss: string;
	iat: number;
	exp: number;
	CommonName: string;
	sub: string;
	uid: string;
	fordBusinessUnitCode: string;
	givenName: string;
	sn: string;
	initials: string;
	mail: string;
	employeeType: string;
	l: string;
	st: string;
	c: string;
	telephoneNumber: string;
	fordCompanyName: string;
	fordDeptCode: string;
	fordDisplayName: string;
	fordDivAbbr: string;
	fordDivision: string;
	fordCompanyCode: string;
	fordHomeCountryCode: string;
	fordManagerCdsid: string;
	fordMRRole: string;
	fordPerLoc: string;
	fordSiteCode: string;
	fordUserType: string;
	apptype: string;
	appid: string;
	authmethod: string;
	auth_time: Date;
	ver: string;
	salesCodes: string[];
	preferredLanguage: string;
}
