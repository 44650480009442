import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {FullCalendarComponent} from '@fullcalendar/angular/full-calendar.component';
import {CalendarEvent} from '../../model/calendar-event';
import { LocaleService } from 'src/app/service/locale-service';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';

/**
 * EventCalendar displays a calendar based on FullCalendar which shows relevant events to users of WBDO.
 * This component is included on the home page of the WBDO app.
 */
@Component({
	selector: 'event-calendar',
	templateUrl: './event-calendar.component.html',
	styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit, OnChanges {
	/** Reference to the FullCalendar element within this component's DOM */
	@ViewChild('calendar') calendarComponent: FullCalendarComponent;
	/** CalendarEvent list managed by this component's parent */
	@Input() events: CalendarEvent[];

	/** These options are referenced by the FullCalendar element in this component. */
	calendarOptions: CalendarOptions;

	constructor(private localeService: LocaleService) {
	}

	/** Setup this component, including setting initial CalendarOptions */
	ngOnInit(): void {
		this.calendarOptions = {
			initialView: 'dayGridMonth',
			plugins: [
				dayGridPlugin,
				interactionPlugin,
				listPlugin
			],
			headerToolbar: {
				left: 'prev,next today',
				center: 'title',
				right: 'dayGridMonth,dayGridWeek,dayGridDay,listMonth'
			},
			eventTextColor: 'white',
			weekends: true,
			selectMirror: true,
			dayMaxEvents: true,
			aspectRatio: 1.25,
			editable: true,
			selectable: true,
			displayEventTime: false,
			events: [],
			locales: [esLocale, frLocale]
		};
		this.localeService.getLanguage().subscribe(locale => this.calendarOptions.locale = locale);
	}

	/** Receives information on updates to the events input field  */
	ngOnChanges(changes: SimpleChanges): void {
		// Calendar must wait for home.component to fetch events
		if (changes.events && changes.events.currentValue !== undefined) {
			const eventsList: Object[] = [];
			for (const event of this.events) {
				eventsList.push({
					id: event.id,
					type: event.type,
					start: event.start,
					end: event.end,
					title: event.content.find(c => c.language === 'en').title
				});
			}
		}
	}
}
