<header id="app-header">
	<app-header *ngIf="!w2Integration" class="text-xl"></app-header>
</header>
<main id="app-content">
	<p-toast position="top-center" styleClass="toast-msg"></p-toast>
	<router-outlet></router-outlet>
</main>
<footer id="footer">
	<app-footer class="text-lg"></app-footer>
</footer>
