import {Component, Input} from '@angular/core';
import {CalendarEvent} from '../../model/calendar-event';
import {Content} from '../../model/content';
import {LocaleService} from '../../service/locale-service';

/** Displays events as a table */
@Component({
	selector: 'event-list',
	templateUrl: './event-list.component.html',
	styleUrls: ['./event-list.component.scss']
})
export class EventListComponent {
	/** CalendarEvent list managed by parent component */
	@Input() events: CalendarEvent[];
	constructor(private localeService: LocaleService) {	}

	/**
	 * Formats a timestamp as a locale date string
	 * @param dateTime Timestamp string to format
	 * @returns Formatted timestamp
	 */
	formatDate(dateTime: string): string {
		return new Date(dateTime).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' });
	}

	/**
	 * Checks if an event does not start on the same day as the preceding event.
	 * @param index Index of event within events list to check
	 * @returns True if the start day of the preceding event is different from the indexed event
	 */
	checkDateDiff(index: number): boolean {
		if (index === 0 || new Date(this.events[index].start).toDateString() !== new Date(this.events[index - 1].start).toDateString()) {
			return true;
		}
		return false;
	}

	/**
	 * Toggle whether an event is shown expanded
	 * @param id HTML ID of the event's expansion <div>
	 */
	expandEvent(id: string): void {
		const divToExpand = document.getElementById(id);
		if (divToExpand.style.display === 'none') {
			divToExpand.style.display = 'block';
		} else {
			divToExpand.style.display = 'none';
		}
	}
	/**
	 * Gets the translated content of an event
	 * @param event Event to process
	 * @param lang Language to use
	 * @returns
	 */
	getContent(event: CalendarEvent, lang: string): Content {
		let cont = event.content.find(c => c.language === lang);
		if (cont === undefined) {
			cont = event.content.find(c => c.language === 'en_override');
		}
		return cont;
	}

	/**
	 * Gets the translated title of an event
	 * @param event Event to process
	 * @param lang Language to use
	 * @returns
	 */
	getTitle(event: CalendarEvent, lang: string): string {
		let cont = event.content.find(c => c.language === lang);
		if (cont === undefined) {
			cont = event.content.find(c => c.language === 'en_override');
		}
		return (cont === undefined ? undefined : cont.title);
	}

	/**
	 * Gets the translated body text of an event
	 * @param event Event to process
	 * @param lang Language to use
	 * @returns
	 */
	getBody(event: CalendarEvent, lang: string): string {
		let cont = event.content.find(c => c.language === lang);
		if (cont === undefined) {
			cont = event.content.find(c => c.language === 'en_override');
		}
		return (cont === undefined ? undefined : cont.body);
	}

}
