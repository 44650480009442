import {Component, OnInit} from '@angular/core';
import {WholesaleService} from '../../service/wholesale-service';
import {InventoryMetrics} from '../../model/inventory-metrics';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {MenuItem} from 'primeng/api';
import {DownloadService} from '../../service/download-service';

@Component({
	selector: 'inventory-metrics',
	templateUrl: './inventory-metrics.component.html',
	styleUrls: ['./inventory-metrics.component.scss']
})
export class InventoryMetricsComponent implements OnInit {
	modelYears: string[];
	modelYear: string;
	inventoryMetricsTotals: Map<string, Map<string,number>>;
	inventoryMetricsData: Map<string, InventoryMetrics[]>;
	inventoryMetricsDownloadData: MenuItem[];

	constructor(private wholesaleService: WholesaleService,
				public ref: DynamicDialogRef,
				public config: DynamicDialogConfig,
				public downloadService: DownloadService) { }

	ngOnInit(): void {
		this.modelYears = [];
		this.inventoryMetricsData = (this.config.data !== undefined ? this.config.data.inventoryMetricsData : new Map());
		this.inventoryMetricsTotals = (this.config.data !== undefined ? this.config.data.inventoryMetricsTotals : new Map());
		if (this.inventoryMetricsData !== undefined) {
			this.modelYears = Array.from(this.inventoryMetricsData.keys());
			this.modelYear = this.modelYears[0];
		}
		this.initializeDownloadData();
	}
	getMetricsTotal(cat: string): number {
		return (this.inventoryMetricsTotals.get(this.modelYear) !== undefined
			? this.inventoryMetricsTotals.get(this.modelYear).get(cat) : 0);
	}

	initializeDownloadData(): void {
		this.inventoryMetricsDownloadData = [
			{
				label: 'CSV',
				icon: 'pi pi-file',
				command: () => {
					this.downloadService.downloadExcelInventoryMetrics(this.inventoryMetricsData, '.csv');
				}
			},
			{
				label: 'Excel',
				icon: 'pi pi-file-excel',
				command: () => {
					this.downloadService.downloadExcelInventoryMetrics(this.inventoryMetricsData, '.xlsx');
				}
			},
			{
				label: 'PDF ',
				icon: 'pi pi-file-pdf',
				command: () => {
					this.downloadService.downloadPdfInventoryMetrics(this.inventoryMetricsData);
				}
			}
		];
	}
}
