<p-toast></p-toast>
	<p-table [columns]="columnHeaders" [value]="vehicleList" id="sims-preferences-table" [tableStyle]="{'min-width': '20rem'}">
		<ng-template pTemplate="header">
			<tr class="w-full">
				<th class="w-6 sims-left-header py-1">
					{{'wbdo.sims.vehicleLinesLabel' | translate }}
				</th>
				<th class="w-12 flex justify-content-end sims-right-header py-1">
					<div *ngIf="!isW2Integration" class="flex">{{'wbdo.sims.mostStandardLabel' | translate }}</div>
					<div *ngIf="isW2Integration"><toggle-all class="flex justify-content-end"
								[vehicleList]="vehicleList">
					</toggle-all></div>
				</th>
			</tr>
			<tr class="w-full" *ngIf="!isW2Integration">
				<th class="w-6 sims-left-header"></th>
				<th class="w-12 flex justify-content-end sims-right-header py-1">
					<toggle-all [vehicleList]="vehicleList"></toggle-all>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-vehicle>

			<tr>
				<td style="width: 10px">{{vehicle.name}}</td>
					<td style="width: 10px">
						<span class="sims-toggle flex flex-row gap-2 justify-content-end">
							<p-inputSwitch class="flex justify-content-end p-jc-center primeng-inputswitch" [(ngModel)]="vehicle.standardConfig"></p-inputSwitch>
							<span class="flex justify-content-end">
								<span class="yes-text" [hidden]="!vehicle.standardConfig">{{'wbdo.sims.yes' | translate }}</span>
								<span class ="no-text" [hidden]="vehicle.standardConfig">{{'wbdo.sims.no' | translate }}</span>
							</span>
						</span>
					</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="footer">
			<tr>
				<td class="reset-default-text text-xs">
					{{'wbdo.sims.wholesaleNote' | translate }}
				</td>
				<td class="sims-reset-default-button">
					<sims-reset-default class="flex justify-content-end" [vehicleList]="vehicleList">
					</sims-reset-default>
				</td>
			</tr>
		</ng-template>
	</p-table>

