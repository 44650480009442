import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {WholesaleService} from '../../service/wholesale-service';
import {Wholesale} from '../../model/wholesale';
import {Table} from 'primeng/table';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {InventoryMetricsComponent} from '../inventory-metrics/inventory-metrics.component';
import {WholesaleVehicle} from '../../model/wholesale-vehicle';
import {WholesaleConfig} from '../../model/wholesale-config';
import {InventoryMetrics} from '../../model/inventory-metrics';
import {ControlValidationComponent} from '../control-validation/control-validation.component';
import {ControlTarget} from '../../model/control-target';
import {SpinnerService} from '../../service/spinner-service';
import {DateTime} from 'luxon';

import {WholesaleStatus} from '../../model/wholesale-status';
import {DownloadService} from '../../service/download-service';
import {TranslateService} from '@ngx-translate/core';
import {UserInfoService} from '../../service/user-info-service';
import {forkJoin, Observable, of} from 'rxjs';
import {dirtyCheck, DirtyComponent} from '@ngneat/dirty-check-forms';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {WholesaleEntity} from '../../model/wholesale-entity';

@Component({
	selector: 'wholesale',
	templateUrl: './wholesale.component.html',
	styleUrls: ['./wholesale.component.scss'],
	providers: [MessageService]
})
export class WholesaleComponent implements OnInit, AfterViewChecked, DirtyComponent {
	@ViewChild('dt1') table: Table;
	@ViewChild('dt2') table2: Table;
	dealerCode: string;
	market: string;
	lincoln: string;
	division: string;
	region: string;
	locale: string;

	ref: DynamicDialogRef | undefined;
	validationRef: DynamicDialogRef | undefined;
	validateCommit: Event;
	downloadGridItems: MenuItem[];
	vehicleLine: string;
	commitmentStatus: string;
	commitmentStatusDisplay: string;
	vehicleLineList: string[];
	wholesaleData: Wholesale[] = [];
	wholesaleExportData: Wholesale[] = [];
	controlTargets: ControlTarget[];
	volumeLevelCommitmentCount = 0;
	totalRecommendationCount = 0;
	totalCommitmentCount = 0;
	wholesaleDataSaveAllowed: boolean;
	wholesaleDataApproveAllowed: boolean;
	productionCycle: string;
	totalOrderCount = 0;
	wholesaleColumns: any[];
	inventoryMetricsData: Map<string, InventoryMetrics[]>;
	inventoryMetricsTotals: any;
	disableIMButton = true;
	disableControlsButton = true;

	wholesaleStatusData: WholesaleStatus;
	statusColor: string;
	statusText: string;
	statusEndDate: string;
	isBlackLabel: boolean;

	greenColor = '#008200';
	redColor = '#c51414';

	vehicles: Map<string, WholesaleVehicle>;

	metricsTooltip: string;
	controlsTooltip: string;
	errorMessage = '';
	isCommitAllowed: boolean;
	isDirty$: Observable<boolean>;
	formBuilder: FormBuilder = new FormBuilder();
	formGroup: FormGroup;
	pIntegerOnly = new RegExp('\\b(0|[1-9][0-9]*)\\b');

	constructor(private wholesaleService: WholesaleService,
				private dialogService: DialogService,
				public spinnerService: SpinnerService,
				public messageService: MessageService,
				private downloadService: DownloadService,
				private translate: TranslateService,
				private userInfoService: UserInfoService,
				private cdr: ChangeDetectorRef) {
	}

	ngAfterViewChecked(): void {
		this.matchWholesaleHeaderRowHeights();
	}

	ngOnInit(): void {
		window.addEventListener('resize', this.matchWholesaleHeaderRowHeights);
		this.userInfoService.getDealerCode().subscribe(dealerCode => {
			this.dealerCode = dealerCode;
		});
		this.userInfoService.getMarket().subscribe(market => {
			this.market = market;
		});
		this.userInfoService.getLocale().subscribe(locale => {
			this.locale = locale;
		});
		this.userInfoService.getLincoln().subscribe(lincoln => {
			this.lincoln = lincoln;
		});
		this.userInfoService.getDivision().subscribe(division => {
			this.division = division;
		});
		this.userInfoService.getRegion().subscribe(region => {
			this.region = region;
		});
		this.userInfoService.getIsBlackLabel().subscribe(isBlackLabel => {
			this.isBlackLabel = isBlackLabel;
		});
		this.formGroup = this.formBuilder.group({
			wholesaleFormData: this.formBuilder.array([])
		});
		this.vehicles = new Map();
		this.wholesaleData = [];
		this.wholesaleExportData = [];
		this.inventoryMetricsData = new Map();
		this.inventoryMetricsTotals = new Map();
		if (this.dealerCode === undefined || this.dealerCode === null) {
			this.dealerCode = 'F81333';
		}

		this.initializeDownloadGridItems();
		this.displayWholesaleStatus();
		let labels: string[];
		forkJoin([
			this.translate.get('wbdo.wholesale.offering'),
			this.translate.get('wbdo.wholesale.availablePepCodes'),
			this.translate.get('wbdo.wholesale.orderRecommendation'),
			this.translate.get('wbdo.wholesale.dealerOrder'),
			this.translate.get('wbdo.wholesale.retailOrderSummary')]).subscribe({
			next: (results) => {
				labels = results;
				this.setWholesaleColumns(labels);
			}
		});
		this.translate.get('wbdo.wholesale.awaitingCommitment').subscribe(mes => this.commitmentStatusDisplay = mes);

	}

	private setWholesaleColumns(labels: string[]): void {
		this.wholesaleColumns = [
			{field: 'offering', header: labels[0], width: '10%'},
			{field: 'pepCodes', header: labels[1], width: '5%'},
			{field: 'orderRecommendation', header: labels[2], width: '10%'},
			{field: 'dealerOrder', header: labels[3], width: '10%'},
			{field: 'retailOrderCountSummary', header: labels[4], width: '25%'}
		];
	}
	resetHeaderHeights(): void {
		document.getElementById('wholesale-left-table-header-row')
			.setAttribute('style', 'auto');
		document.getElementById('wholesale-right-table-header-row')
			.setAttribute('style', 'auto');

	}

	resetRowHeights(row: HTMLElement): void {
		row.setAttribute('style', 'auto');
	}

	private matchWholesaleHeaderRowHeights(): void {
		// reset heights
		this.resetHeaderHeights();
		// determine taller header row
		const heightLeft: number = document.getElementById('wholesale-left-table-header-row').offsetHeight;
		const heightRight: number = document.getElementById('wholesale-right-table-header-row').offsetHeight;
		if (heightLeft > heightRight) {
			document.getElementById('wholesale-right-table-header-row')
				.setAttribute('style', this.buildHeightAttributeString(heightLeft));
		} else if (heightLeft < heightRight) {
			document.getElementById('wholesale-left-table-header-row')
				.setAttribute('style', this.buildHeightAttributeString(heightRight));
		}
		// reset and determine max row heights
		const rowsLeft = document.getElementsByClassName('wholesale-left-table-row');
		const rowsRight = document.getElementsByClassName('wholesale-right-table-row');
		let maxHeight = 0;
		Array.from(rowsLeft).forEach((row: HTMLElement) => {
			this.resetRowHeights(row);
			if (row.clientHeight > maxHeight) {
				maxHeight = row.clientHeight;
			}
		});
		Array.from(rowsRight).forEach((row: HTMLElement) => {
			this.resetRowHeights(row);
			if (row.clientHeight > maxHeight) {
				maxHeight = row.clientHeight;
			}
		});
		// set row heights
		Array.from(rowsLeft).forEach((row: HTMLElement) => {
			row.setAttribute('style', this.buildHeightAttributeString(maxHeight));
		});
		Array.from(rowsRight).forEach((row: HTMLElement) => {
			row.setAttribute('style', this.buildHeightAttributeString(maxHeight));
		});
	}

	private buildHeightAttributeString(height: number): string {
		return 'height:' + height.toString() + 'px';
	}

	getVehicles(): void {
		this.wholesaleService.fetchVehicles(this.market, this.lincoln, this.dealerCode).subscribe({
			next: (res: WholesaleVehicle[]) => {
				Object.keys(res).forEach((key: string) => {
					this.vehicles.set(res[key].name, res[key]);
				});
				this.vehicleLineList = res.map(x => x.name);
				this.vehicleLine = this.vehicleLineList[0];
				this.onVehicleLineChange(this.vehicleLine);
			},
			error: () => {
				this.translate.get('wbdo.wholesale.loadFailure.vehicles').subscribe(mes => {
					this.showMessageServiceError(mes, true);
				});
			}
		});
	}

	showMessageServiceError(message: string, sticky?: boolean, severity?: string): void {
		this.errorMessage = '';
		if (sticky) {
			this.messageService.clear();
		} //Clear previous sticky error message.

		this.messageService.add({
			severity: severity ? severity : 'error',
			summary: severity ? severity.charAt(0).toUpperCase() + severity.slice(1) : 'Error',
			sticky,
			detail: message
		});
		this.errorMessage = message;
	}

	initializeDownloadGridItems(): void {
		this.downloadGridItems = [
			{
				label: 'CSV',
				icon: 'pi pi-file',
				command: () => {
					this.csvAndExcelHelper('.csv');
				}
			},
			{
				label: 'Excel',
				icon: 'pi pi-file-excel',
				command: () => {
					this.csvAndExcelHelper('.xlsx');
				}
			},
			{
				label: 'PDF ',
				icon: 'pi pi-file-pdf',
				command: () => {
					const cols = this.wholesaleColumns.map((col) => ({title: col.header, dataKey: col.field}));
					this.downloadService.downloadPdf(
						this.wholesaleExportData,
						cols,
						this.dealerCode + '_' + this.vehicleLine + '_' +
						DateTime.now().toFormat('MMM_dd_yyyy') + '.pdf',
						this.vehicleLine,
						this.commitmentStatusDisplay,
						this.commitmentStatus);
				}
			}
		];
	}

	// Helper function to reduce SonarQube duplicate code
	private csvAndExcelHelper(fileExt: string): void {
		const rows = Array.from(this.wholesaleExportData.map(x => this.wholesaleColumns.map(y => x[y.field])));
		const cols = Array.from(this.wholesaleColumns.map(x => x.header).values());
		this.downloadService.downloadExcel(rows,
			cols,
			this.dealerCode + '_' + this.vehicleLine + '_' + DateTime.now().toFormat('MMM-dd-yyyy'),
			this.vehicleLine,
			this.vehicleLine,
			this.commitmentStatusDisplay,
			this.commitmentStatus,
			fileExt);
	}

	displayInventoryMetrics(): void {
		let imMessage: string;
		this.translate.get('wbdo.wholesale.inventoryMetricsDialog.inventoryMetricsHeader').subscribe(message => imMessage = message);
		this.ref = this.dialogService.open(InventoryMetricsComponent, {
			header: imMessage,
			width: '60%',
			maximizable: false,
			styleClass: 'inventory-metrics-dialog',
			data: {
				inventoryMetricsData: this.inventoryMetricsData,
				inventoryMetricsTotals: this.inventoryMetricsTotals
			}
		});
		this.ref.onClose.subscribe();
	}

	displayControlValidation(): void {
		this.closeAllDialogs();
		let controlMessage: string;
		this.translate.get('wbdo.wholesale.controlMessage').subscribe(message => controlMessage = message);
		this.validationRef = this.dialogService.open(ControlValidationComponent, {
			header: controlMessage,
			width: '15%',
			maximizable: false,
			draggable: true,
			modal: false,
			contentStyle: {
				border: '1px solid #c8c9c7', background: '#fff',
				color: '#4d4d4d', padding: '0rem'
			},
			styleClass: 'controls-dialog',
			data: {
				wholesaleControlTargets: this.controlTargets
			}
		});
		this.validationRef.onClose.subscribe();
	}

	fetchMetrics(): void {
		this.wholesaleService.fetchInventoryMetrics(this.dealerCode, this.market, this.division, this.region,
			this.vehicles.get(this.vehicleLine).ptvl, this.vehicles.get(this.vehicleLine).name,
			this.vehicles.get(this.vehicleLine).regAllocVlCode).subscribe({
			next: (metrics: Map<string, InventoryMetrics[]>) => {
				if (Object.keys(metrics).length > 0) {
					this.inventoryMetricsData.clear();
					this.inventoryMetricsTotals.clear();
					Object.keys(metrics).forEach((year: string) => {
						this.inventoryMetricsData.set(year, metrics[year]);
					});
					for (const year of this.inventoryMetricsData.keys()) {
						this.inventoryMetricsTotals.set(year, new Map([['dealerStock', 0], ['inTransit', 0], ['inSystem', 0]]));
					}
					for (const metricsYear of this.inventoryMetricsTotals.keys()) {
						const yearData = this.inventoryMetricsTotals.get(metricsYear);
						for (const category of yearData.keys()) {
							yearData.set(category, metrics[metricsYear].map(x => x[category]).reduce((a, b) => a + b, 0));
						}
					}
				} else {
					this.clearMetrics();
					this.disableIMButton = true;
					forkJoin([this.translate.get('wbdo.wholesale.loadFailure.noMetrics'),
						this.translate.get('wbdo.wholesale.loadFailure.iMetrics')]).subscribe({
						next: (results) => {
							this.metricsTooltip = results[0];
							this.showMessageServiceError(results[0], false, 'info');
						}
					});
				}
				this.displayInventoryMetrics();
			},
			error: () => {
				this.clearMetrics();
				this.translate.get('wbdo.wholesale.loadFailure.iMetrics').subscribe(mes => {
					this.showMessageServiceError(mes + this.vehicleLine, true);
				});
			}
		});
	}

	clearMetrics(): void {
		this.inventoryMetricsData.clear();
		this.disableIMButton = true;
	}

	fetchControlTargets(): void {
		this.wholesaleService.fetchControlValidation(this.dealerCode, this.market,
			this.vehicles.get(this.vehicleLine).ptvl,
			this.vehicles.get(this.vehicleLine).modelYear,
			this.vehicles.get(this.vehicleLine).regAllocVlCode).subscribe({
			next: (controls: ControlTarget[]) => {
				if (controls !== null && controls.length > 0) {
					this.controlTargets = [];
					controls.forEach((control) => {
						if (this.wholesaleData.find(c => control.configCodes.includes(c.id))) {
							this.controlTargets.push(control);
						}
					});

					if (this.controlTargets.length === 0) {
						this.clearControls();
						this.translate.get('wbdo.wholesale.loadFailure.noControls').subscribe(mes => {
							this.controlsTooltip = mes;
							this.showMessageServiceError(mes, false, 'info');
						});
					} else {
						this.disableControlsButton = false;
						this.controlsTooltip = '';
						this.displayControlValidation();
					}
				} else {
					this.clearControls();
					this.translate.get('wbdo.wholesale.loadFailure.noControls').subscribe(mes => {
						this.showMessageServiceError(mes, false, 'info');
					});
				}
			},
			error: () => {
				this.clearControls();
				this.translate.get('wbdo.wholesale.loadFailure.controls').subscribe(mes => {
					this.showMessageServiceError(mes + this.vehicleLine, true);
				});
			}
		});
	}

	assignWholesaleConfigResponse(result: WholesaleConfig): void {
		this.wholesaleData = [];
		if (result !== undefined) {
			if (result.commitmentStatus !== '' && result.commitmentStatus.length !== 0) {
				forkJoin([this.translate.get('wbdo.wholesale.approved'),
					this.translate.get('wbdo.wholesale.saved')]).subscribe({
					next: results => {
						this.commitmentStatus = result.commitmentStatus === 'COMMIT' ? results[0] : results[1];
					}
				});
			}
			this.commitmentStatusDisplay = this.getVolumeCommitmentCountDisplay(result);

			this.totalCommitmentCount = result.totalCommitmentCount;
			this.wholesaleDataSaveAllowed = result.saveAllowed;
			this.wholesaleDataApproveAllowed = result.approveAllowed;
			this.productionCycle = result.productionCycle;
			this.volumeLevelCommitmentCount = result.totalVolumeLevelCommitmentCount || 0;

			this.entitiesToWholesaleData(result.entities);
			this.sumRecommendationAndRetailOrderTotal();
		}
	}

	makeConfigCallAfterSaveApprove(vl: string): void {
		this.wholesaleService.fetchWholesaleDetails(this.dealerCode, this.market,
			this.vehicles.get(vl), this.division, this.region, this.isBlackLabel)
			.subscribe({
				next: (response: WholesaleConfig): void => {
					this.assignWholesaleConfigResponse(response);
					this.wholesaleExportData = JSON.parse(JSON.stringify(this.wholesaleData)); // Deep copy
				},
				error: () => {
					this.handleWholesalePageError();
				}
			});
	}

	onVehicleLineChange(vl: string): void {
		this.closeAllDialogs();
		this.messageService.clear();
		this.clearControls();
		this.clearMetrics();
		this.messageService.clear();
		this.commitmentStatus = '';
		this.resetSort();
		this.wholesaleService.fetchWholesaleDetails(this.dealerCode, this.market,
			this.vehicles.get(vl), this.division, this.region, this.isBlackLabel)
			.subscribe({
				next: (response: WholesaleConfig): void => {
					this.assignWholesaleConfigResponse(response);
					this.setIsInventoryMetricsEnabled();
					this.fetchControlTargets();
					this.loadFormArray();
					this.wholesaleExportData = JSON.parse(JSON.stringify(this.wholesaleData)); // Deep copy
				},
				error: () => {
					this.handleWholesalePageError();
				}
			});
	}

	loadFormArray(): void {
		const formArray = this.formGroup.get('wholesaleFormData') as FormArray;
		formArray.clear();
		this.wholesaleData.map((x: Wholesale) => {
			const group = this.formBuilder.group({
				dealerOrder: [x.dealerOrder]
			});
			formArray.push(group);
		});
		this.isDirty$ = dirtyCheck(formArray, of(this.wholesaleData.map(x => ({dealerOrder: x.dealerOrder}))));
	}

	entitiesToWholesaleData(entities: WholesaleEntity[]): void {
		entities.forEach((x: WholesaleEntity): void => {
			this.wholesaleData.push({
				id: x.code,
				offering: x.description,
				pepCodes: x.pepCodes,
				orderRecommendation: (x.recommendationCount === undefined ? 0 : x.recommendationCount),
				dealerOrder: (x.commitmentCount === undefined ? 0 : x.commitmentCount),
				retailOrderCountSummary: (x.retailOrderCount === undefined ? 0 : x.retailOrderCount),
			});
		});
		this.matchWholesaleHeaderRowHeights();
	}

	getVolumeCommitmentCountDisplay(result: WholesaleConfig): string {
		let display: string;
		this.translate.get('wbdo.wholesale.awaitingCommitment').subscribe(
			mes => display = result.totalVolumeLevelCommitmentCount > 0
				? String(result.totalVolumeLevelCommitmentCount) : mes);
		return display;
	}

	wholesaleDataExists(): boolean {
		return this.wholesaleData && this.wholesaleData.length > 0;
	}

	handleWholesalePageError(): void {
		this.wholesaleData = [];
		this.wholesaleExportData = [];
		this.disableIMButton = true;
		this.disableControlsButton = true;
		this.totalOrderCount = 0;
		this.totalRecommendationCount = 0;
		this.totalCommitmentCount = 0;
		this.translate.get('wbdo.wholesale.awaitingCommitment').subscribe(mes => {
			this.commitmentStatusDisplay = mes;
		});

		this.translate.get('wbdo.wholesale.loadFailure.wholesale').subscribe(mes => {
			this.showMessageServiceError(mes + this.vehicleLine, true);
		});
	}

	clearControls(): void {
		this.controlTargets = [];
		this.disableControlsButton = true;
	}

	closeAllDialogs(): void {
		if (this.dialogService) {
			this.dialogService.dialogComponentRefMap.forEach(dialog => {
				dialog.destroy();
			});
		}
	}

	sumRecommendationAndRetailOrderTotal(): void {
		let recommendation = 0;
		let retailOrders = 0;
		this.wholesaleData.forEach(w => {
			recommendation = recommendation + Number(w.orderRecommendation);
			retailOrders = retailOrders + Number(w.retailOrderCountSummary);
		});
		this.totalRecommendationCount = recommendation;
		this.totalOrderCount = retailOrders;
	}

	formatStatusDate(date: string): string {
		let closesText: string;
		this.translate.get('wbdo.wholesale.closes').subscribe(text => closesText = text);
		if (date === undefined || date === null) {
			return '';
		}
		return '(' + closesText + ' - ' + DateTime.fromISO(date).toFormat('MMM dd yyyy') +
			' @ ' + DateTime.fromISO(date).toFormat('hh:mm a ZZZZ') + ')';
	}

	displayWholesaleStatus(): void {
		this.wholesaleService.fetchWholesaleStatus(this.dealerCode, this.market)
			.subscribe({
				next: (response: WholesaleStatus): void => {
					this.wholesaleStatusData = response;
					forkJoin([this.translate.get('wbdo.wholesale.otdOfferingSelectionOpen'),
						this.translate.get('wbdo.wholesale.otdOfferingSelectionClosed')]).subscribe({
						next: results => {
							this.statusText = this.wholesaleStatusData.wholesaleStatus ? results[0] : results[1];
						}
					});
					if (this.wholesaleStatusData.wholesaleStatus) {
						this.statusColor = this.greenColor;
						this.statusEndDate = this.formatStatusDate(response.endDate);
					} else {
						this.statusColor = this.redColor;
						this.statusEndDate = '';
					}
					this.getVehicles();
				},
				error: (): void => {
					this.wholesaleStatusData = null;
					this.statusText = null;
					this.statusColor = null;
					this.statusEndDate = null;
					if (this.vehicleLine === undefined) {
						const translationKey = 'wbdo.wholesale.GeneralError';
						this.translate.get(translationKey).subscribe(message => this.showMessageServiceError(message, true));
					} else {
						this.showMessageServiceError(this.translate.get('wbdo.wholesale.loadFailure.wholesale') + this.vehicleLine, true);
					}
				}
			});
	}

	sumAndValidateCommitment(event: Event): void {
		// For repeat zeros
		const inputValue = (event.target as HTMLInputElement).value;
		if (!this.pIntegerOnly.test(inputValue)) {
			(event.target as HTMLInputElement).value = inputValue.slice(inputValue.search(/[^0]/), inputValue.length);
		}
		this.validateCommit = event;
	}

	resetSort() {
		if (this.table !== undefined && this.table2 !== undefined) {
			this.table.sortOrder = 0;
			this.table.sortField = '';
			this.table2.sortOrder = 0;
			this.table2.sortField = '';

			this.table.reset();
			this.table2.reset();
		}
	}

	onCommitAllowUpdate(isCommitAllowed: boolean): void {
		this.isCommitAllowed = isCommitAllowed;
		this.cdr.detectChanges();
	}

	onTotalCommitmentCountUpdate(totalCommitmentCount: number): void {
		this.totalCommitmentCount = totalCommitmentCount;
		this.cdr.detectChanges();
	}
	setIsInventoryMetricsEnabled(): void {
		this.wholesaleService.fetchInventoryMetricsEnable(this.dealerCode, this.vehicles.get(this.vehicleLine).ptvl).subscribe({
			next: (response: WholesaleConfig): void => {
				this.disableIMButton = false;
				this.metricsTooltip = '';
			},
			error: () => {
				forkJoin([this.translate.get('wbdo.wholesale.loadFailure.noMetrics'),
					this.translate.get('wbdo.wholesale.loadFailure.iMetrics')]).subscribe({
					next: (results) => {
						this.metricsTooltip = results[0];
						this.showMessageServiceError(results[0], false, 'info');
					}
				});
				this.disableIMButton = true;
			}
		});
	}
}
