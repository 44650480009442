import {BehaviorSubject, Observable} from 'rxjs';
import {Dealer, Permissions} from './init-service';

export class UserInfoService {
	private firstNameSubject$ = new BehaviorSubject('Unknown');
	private dealerCodeSubject$ = new BehaviorSubject( '');
	private salesCodesSubject$ = new BehaviorSubject<Dealer[]>( []);
	private localeSubject$ = new BehaviorSubject( '');
	private w2IntegrationSubject$ = new BehaviorSubject( false);
	private marketSubject$ = new BehaviorSubject( '');
	private regionSubject$ = new BehaviorSubject( '');
	private divisionSubject$ = new BehaviorSubject( '');
	private lincolnSubject$ = new BehaviorSubject( '');
	private permissionsSubject$ = new BehaviorSubject<Permissions[]>( []);
	private accessTokenSubject$ = new BehaviorSubject( '');
	private userInfoSubject$ = new BehaviorSubject( '');
	private strAccessTokenSubject$ = new BehaviorSubject<any>({});
	private isBlackLabelSubject$ = new BehaviorSubject<boolean>(false);
	firstName$: Observable<string> = this.firstNameSubject$.asObservable();
	dealerCode$: Observable<string> = this.dealerCodeSubject$.asObservable();
	salesCodes$: Observable<Dealer[]> = this.salesCodesSubject$.asObservable();
	locale$: Observable<string> = this.localeSubject$.asObservable();
	w2Integration$: Observable<boolean> = this.w2IntegrationSubject$.asObservable();
	market$: Observable<string> = this.marketSubject$.asObservable();
	region$: Observable<string> = this.regionSubject$.asObservable();
	division$: Observable<string> = this.divisionSubject$.asObservable();
	lincoln$: Observable<string> = this.lincolnSubject$.asObservable();
	permissions$: Observable<Permissions[]> = this.permissionsSubject$.asObservable();
	accessToken$: Observable<string> = this.accessTokenSubject$.asObservable();
	userInfo$: Observable<string> = this.userInfoSubject$.asObservable();
	strAccessToken$: Observable<any> = this.strAccessTokenSubject$.asObservable();
	isBlackLabel$: Observable<boolean> = this.isBlackLabelSubject$.asObservable();
	setFirstName(name: string) {
		this.firstNameSubject$.next(name);
	}
	getFirstName(): Observable<string> {
		return this.firstName$;
	}
	setDealerCode(dealerCode: string) {
		this.dealerCodeSubject$.next(dealerCode);
	}
	getDealerCode(): Observable<string> {
		return this.dealerCode$;
	}
	setSalesCodes(salesCodes: Dealer[]) {
		this.salesCodesSubject$.next(salesCodes);
	}
	getSalesCodes(): Observable<Dealer[]> {
		return this.salesCodes$;
	}
	setLocale(locale: string) {
		this.localeSubject$.next(locale);
	}
	getLocale(): Observable<string> {
		return this.locale$;
	}
	setW2Integration(w2Integration: boolean) {
		this.w2IntegrationSubject$.next(w2Integration);
	}
	getW2Integration(): Observable<boolean> {
		return this.w2Integration$;
	}
	setMarket(market: string) {
		this.marketSubject$.next(market);
	}
	getMarket(): Observable<string> {
		return this.market$;
	}
	setRegion(market: string) {
		this.regionSubject$.next(market);
	}
	getRegion(): Observable<string> {
		return this.region$;
	}
	setDivision(division: string) {
		this.divisionSubject$.next(division);
	}
	getDivision(): Observable<string> {
		return this.division$;
	}
	setLincoln(division: string) {
		this.lincolnSubject$.next(division);
	}
	getLincoln(): Observable<string> {
		return this.lincoln$;
	}
	setPermissions(permissions: Permissions[]) {
		this.permissionsSubject$.next(permissions);
	}
	getPermissions(): Observable<Permissions[]> {
		return this.permissions$;
	}
	setAccessToken(accessToken: string) {
		this.accessTokenSubject$.next(accessToken);
	}
	getAccessToken(): Observable<string> {
		return this.accessToken$;
	}
	setUserInfo(userInfo: string) {
		this.userInfoSubject$.next(userInfo);
	}
	setUserSelectedLang(lang: string) {
		localStorage.setItem('userSelectedLang', lang);
	}
	getUserSelectedLang(): string | null {
		return localStorage.getItem('userSelectedLang');
	}
	getStrAccessToken(): Observable<string> {
		return this.strAccessToken$;
	}
	setIsBlackLabel(isBlackLabel: boolean) {
		this.isBlackLabelSubject$.next(isBlackLabel);
	}
	getIsBlackLabel(): Observable<boolean> {
		return this.isBlackLabel$;
	}
}
