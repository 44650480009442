<div class="main-card">
	<div style="font-size: 1.875vw;">{{ 'wbdo.home.messagesHeader' | translate }}</div>
	<div class="flex">
		<div class="flex gap-2.5"
			 style="min-width: 50%; font-size: 1.5vw; padding-bottom: 1rem; vertical-align: middle; font-family: sans-serif">
			<div class="flex align-items-center" style="margin: 1.5rem 1rem 0.5rem 0">
				<p-radioButton name="status" value="Active" [(ngModel)]="messageStatus" id="active"></p-radioButton>
				<label for="active" class="ml-2">Active</label>
			</div>
			<div class="flex align-items-center" style="margin: 1.5rem 1rem 0.5rem 0">
				<p-radioButton name="status" value="Archive" [(ngModel)]="messageStatus" id="archive"></p-radioButton>
				<label for="archive" class="ml-2">Archive</label>
			</div>
		</div>
		<div style="min-width: 50%; font-size: 1.875vw;">
			{{ 'wbdo.home.eventsHeader' | translate }}
		</div>
	</div>
	<p-splitter [style]="{ minHeight: '100vh' }" styleClass="mb-5" [minSizes]="[50, 50]">
		<ng-template pTemplate>
			<message-list [filteredData]="filteredMessages"
						  [messageData]="messageData">
			</message-list>
		</ng-template>
		<ng-template pTemplate>
			<div class="display-panel-calendar-card">
				<event-list [events]="events">
				</event-list>
				<event-calendar [events]="events">
				</event-calendar>
			</div>
		</ng-template>
	</p-splitter>
</div>
