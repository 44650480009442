export interface ControlValidationFailure {
	underControl: boolean;
	validationFailures: ValidationFailures[];
}
interface ValidationFailures {
	feature: string;
	controlMixRate: number;
	target: number;
}

export function isControlValidationFailure(arg: any): arg is ControlValidationFailure {
	return arg && arg.underControl !== undefined && typeof(arg.underControl) === 'boolean';
}
