import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class SimsPreferencesService {

	simsPrefUrl = environment.baseUrl + '/api/v1/sims-preferences';

	private simsData$ = new BehaviorSubject<SimsPreferences>(null);
	simsDataObs = this.simsData$.asObservable();

	market: string;

	constructor(private http: HttpClient) {
	}

	setSimsData(simsData: SimsPreferences): void {
		this.simsData$.next(simsData);
	}

	getSimsData(): Observable<SimsPreferences> {
		return this.simsDataObs;
	}

	public toggleAllConfig(enable: boolean): void {
		this.simsData$.getValue().vehicleLines.forEach(vehicle => vehicle.standardConfig = enable);
	}

	public fetchSimsPreferencesData(dealerCode: string, market: string, division: string): Observable<Object> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json',
			}),
			params: new HttpParams()
				.set('market', market)
				.set('lincoln', division[0] !== 'F')
		};

		return this.http.get<Object>(this.pathWithDealerCode(dealerCode, 'sims'), httpOptions);
	}

	public postSimsPreferencesData(dealerCode: string): Observable<Object> {
		const httpOptions = {
			headers: new HttpHeaders({
				'content-type': 'application/json',
			})
		};
		const postObject = {
			market: this.simsData$.getValue().market,
			updateBy: this.simsData$.getValue().updateBy,
			updatedTimeStamp: this.simsData$.getValue().updatedTimeStamp,
			vehicleLines: this.simsData$.getValue().vehicleLines
		};
		return this.http.post<SimsPreferencesResponse>(this.pathWithDealerCode(dealerCode, 'sims'), postObject, httpOptions);
	}

	public pathWithDealerCode(dealerCode: string, path: string): string {
		return `${this.simsPrefUrl}/${dealerCode}/${path}`;
	}

}

export interface SimsPreferences {
	market: string;
	updateBy: string;
	updatedTimeStamp: string;
	vehicleLines: VehicleLine[];
}

export interface VehicleLine {
	name: string;
	code: string;
	standardConfig: boolean;
}

export interface SimsPreferencesResponse {
	commitmentStatus: string;
	simsPreferences: SimsPreferences;
}
