<div class="surface-ground">
	<wholesale-banner
		[statusText]="statusText"
		[statusColor]="statusColor"
		[statusEndDate]="statusEndDate">
	</wholesale-banner>
	<p-toolbar id="vehicle-line-row">
		<div class="p-toolbar-group-start">
			<div class="card grid">
				<div class="col-12 flex flex-wrap gap-2 w-full align-items-center">
					<span class="p-float-label">
						<p-dropdown id="vehicle-line-dropdown" [disabled]="spinnerService.visibility | async"
									[options]="vehicleLineList" ngDefaultControl
									[(ngModel)]="vehicleLine" ngDefaultControl (onChange)="onVehicleLineChange(vehicleLine)"
									inputId="float-label"></p-dropdown>
						<label id="vehicle-line-dropdown-label"
							   for="float-label">{{ 'wbdo.wholesale.vehicleLine' | translate }}</label>
					</span>
					<div *ngIf="wholesaleDataExists()"
						 class="vehicle-commitment text-xl">{{ 'wbdo.wholesale.totalVehicleCommitmentVolume' | translate }}:
						{{ commitmentStatusDisplay }}
					</div>
				</div>

			</div>
		</div>
		<div class="grid">
		</div>
		<div class="p-toolbar-group-end">
			<div class="card grid">
				<div class="col-12 flex flex-wrap gap-2 w-full">
					<p-button class="header-button" label="{{ 'wbdo.wholesale.inventoryMetrics' | translate }}"
							  [disabled]="disableIMButton || (spinnerService.visibility | async)" (onClick)="fetchMetrics()"
							  [pTooltip]="metricsTooltip" tooltipPosition="bottom"></p-button>
					<p-button class="header-button" label="{{ 'wbdo.wholesale.clickToViewControls' | translate }}"
							  [disabled]="disableControlsButton || (spinnerService.visibility | async)" (onClick)="displayControlValidation()"
							  [pTooltip]="controlsTooltip" tooltipPosition="bottom"></p-button>
					<p-button [disabled]="!wholesaleDataExists() || (spinnerService.visibility | async)" class="header-button" type="button"
							  label="{{ 'wbdo.wholesale.downloadGrid' | translate }}"
							  (onClick)="menu.toggle($event)"
							  icon="pi pi-chevron-down" iconPos="right"></p-button>
					<p-menu #menu [model]="downloadGridItems" [popup]="true"></p-menu>
				</div>
			</div>
		</div>
	</p-toolbar>
	<div id="progress-bar-wrapper" style="height:6px">
		<p-progressBar *ngIf="spinnerService.visibility | async"
					   mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
	</div>
	<div id="grid-summary-container" class="grid">
		<div class="col-9">
			<div class="p-0 h-full w-full">
				<div class="shadow-3 pt-5 h-full flex flex-column surface-card" style="border-radius: 6px">
					<wholesale-save-approve
						[wholesaleData]="wholesaleData"
						[wholesaleStatusData]="wholesaleStatusData"
						[vehicles]="vehicles"
						[vehicleLine]="vehicleLine"
						[validateCommit]="validateCommit"
						[controlTargets]="controlTargets"
						[wholesaleDataSaveAllowed]="wholesaleDataSaveAllowed"
						[wholesaleDataApproveAllowed]="wholesaleDataApproveAllowed"
						[volumeLevelCommitmentCount]="volumeLevelCommitmentCount"
						[totalCommitmentCount]="totalCommitmentCount"
						[productionCycle]="productionCycle"
						[commitmentStatus]="commitmentStatus"
						(isCommitAllowedEmitter)="onCommitAllowUpdate($event)"
						(totalCommitmentCountEmitter)="onTotalCommitmentCountUpdate($event)">
					</wholesale-save-approve>
					<form [formGroup]="formGroup">
						<p-table #dt1 styleClass="p-datatable-striped"
								 [value]="wholesaleData"
								 [columns]=wholesaleColumns>
							<ng-template pTemplate="header">
								<tr id="wholesale-left-table-header-row">
									<th class="text-center" pSortableColumn="offering" style='width: 10%'
										scope="col">{{ 'wbdo.wholesale.offering' | translate }}
										<p-sortIcon field="offering"></p-sortIcon>
									</th>
									<th class="text-center" style='width: 5%'
										scope="col">{{ 'wbdo.wholesale.availablePepCodes' | translate }}
									</th>
									<th class="text-center" style='width: 10%'
										scope="col">{{ 'wbdo.wholesale.orderRecommendation' | translate }}
									</th>
									<th class="text-center" pSortableColumn="dealerOrder" style='width: 10%'
										scope="col">{{ 'wbdo.wholesale.dealerOrder' | translate }}
										<p-sortIcon field="dealerOrder"></p-sortIcon>
									</th>
								</tr>
								<tr>
									<td style='width: 10%'></td>
									<td style='width: 5%'></td>
									<td id="total-recommendation-count" class="text-center font-semibold" style='width: 10%'
										*ngIf="wholesaleDataExists()">{{ 'wbdo.wholesale.total' | translate }}:
										{{ this.totalRecommendationCount }}
									</td>
									<td id="total-commitment-count" class="text-center font-semibold" style='width: 10%'
										*ngIf="wholesaleDataExists()">{{ 'wbdo.wholesale.total' | translate }}:
										{{ this.totalCommitmentCount }}
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage" let-wholesale>
								<tr *ngIf="wholesale !== undefined">
									<td [attr.colspan]="wholesale.length">
										{{ 'wbdo.general.noRecords' | translate }}
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-wholesale let-i="rowIndex"
										 formArrayName="wholesaleFormData">
								<tr [formGroupName]="i" class="wholesale-left-table-row">
									<td id="{{wholesale.id}}"
										style='width: 10%; padding-left: 1rem'>{{ wholesale.offering }}
									</td>
									<td class="text-center" style='width: 5%'>{{ wholesale.pepCodes }}</td>
									<td class="text-center" style='width: 10%'>{{ wholesale.orderRecommendation }}</td>
									<td class="text-center" style='width: 10%; height:40px' *ngIf="isCommitAllowed">
										<input [(ngModel)]="wholesale.dealerOrder" formControlName="dealerOrder"
											   type="text" minLength="0" maxLength="3"
											   pattern="^([1-9][0-9]{0,2})$"
											   (input)="sumAndValidateCommitment($event)"
											   [pKeyFilter]="pIntegerOnly"
											   style="width: 35px;height: 25px;text-align:center"/>
									</td>
									<td class="text-center" style='width: 15%; height:40px' *ngIf="!isCommitAllowed">
										{{ wholesale.dealerOrder }}
									</td>
								</tr>
							</ng-template>
						</p-table>
					</form>
				</div>
			</div>
		</div>
		<div class="col-3">
			<div class="p-0 h-full w-full">
				<div class="shadow-3 pt-5 h-full flex flex-column surface-card" style="border-radius: 6px">
					<div class="grid  " style="height: 70px;" *ngIf="wholesaleDataExists()">
						<div class="col-1 flex  gap-2 ">

						</div>
						<div class=" col-11 flex  gap-2 align-items-center justify-content-center">
							<span class="text-lg font-bold p-element"></span>
						</div>
					</div>
					<p-table #dt2 styleClass="p-datatable-striped"
							 [value]="wholesaleData"
							 [columns]=wholesaleColumns
					>
						<ng-template pTemplate="header">
							<tr id="wholesale-right-table-header-row">
								<th [hidden]="true" scope="col">{{ 'wbdo.wholesale.offering' | translate }}</th>
								<th class="text-center" style='min-width: 25%'
									scope="col">{{ 'wbdo.wholesale.retailOrderCountSummary' | translate }}
								</th>
							</tr>
							<tr>
								<td id="total-retail-order-count" class="text-center font-semibold" style='width: 25%'
									*ngIf="wholesaleDataExists()">{{ 'wbdo.wholesale.total' | translate }}:
									{{ this.totalOrderCount }}
								</td>
							</tr>

						</ng-template>

						<ng-template pTemplate="emptymessage" let-wholesale>
							<tr *ngIf="wholesale !== undefined">
								<td [attr.colspan]="wholesale.length">
									{{ 'wbdo.general.noRecords' | translate }}
								</td>
							</tr>
						</ng-template>

						<ng-template pTemplate="body" let-wholesale>
							<tr class="wholesale-right-table-row">
								<td [hidden]="true">{{ wholesale.offering }}</td>
								<td class="text-center"
									style='min-width: 25%;height:42px'>{{ wholesale.retailOrderCountSummary }}
								</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
		</div>
	</div>
</div>
<p-toast position="bottom-right"></p-toast>
