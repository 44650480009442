import {Injectable, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/** Class for showing and hiding spinners
 */
@Injectable({
	providedIn: 'root'
})
export class SpinnerService {
	visibility: BehaviorSubject<boolean>;
	@Input() diameter: number;

	constructor() {
		this.visibility = new BehaviorSubject(false);
	}

	show() {
		this.visibility.next(true);
	}

	hide() {
		this.visibility.next(false);
	}
}
